/* branches */

.component {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: visible;
}

.component header {
    padding: 3rem 2rem;
    border-radius: 1rem;

    display: grid;
    grid-template-columns: auto max-content;
    gap: 4rem;
}

.component.pabx header {
    padding: 2rem;

    grid-template-columns: 1fr .5fr max-content;
}

.info {
    display: flex;
    align-items: center;
}

.pabx .info {
    display: block;
}

.info > h2 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.info > h2 i {
    display: contents;
}

.info > h2 i svg {
    font-size: inherit;
}

.component.pabx header h2,
.component.pabx header h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.pabx .info h2 {
    display: block;
    font-weight: 500;
}

.branches > h3 {
    display: flex;
    align-items: center;
    font-weight: normal;
    gap: .5rem;
}

.branches > h3 i {
    font-size: 1.5rem;
    margin-top: auto;
}

.info table {
    display: grid;
    grid-template-columns: minmax(max-content, auto) max-content;
    gap: 1rem .25rem;
}

.branches table {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 1rem 2rem;
}

.branches .form {
    background-color: green;
}

.component header thead,
.component header tbody,
.component header tr {
    display: contents;
}

.component header th {
    text-align: start;
    font-weight: normal;
    font-size: 1.25rem;
}

.component header td {
    text-align: start;
    font-size: 1.75rem;
}

.actions {
    width: min-content;

    display: flex;
    justify-content: space-between;
    gap: .5rem;

    margin-left: auto;
}

.actions button {
    all: unset;

    width: max-content;
    height: max-content;

    font-size: 2rem;
    padding: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 200ms ease;

    cursor: pointer;
}

.component:not(.open) .actions .toggle {
    transform: rotateZ(180deg);
}

.actions .refresh:disabled {
    cursor: default;
    pointer-events: none;
    opacity: .5;
}

.pabx .actions {
    flex-direction: column;
}

.component > section {
    padding-bottom: 1rem;
}

.component .form {
    overflow: visible;
}

.component .form form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    gap: 1rem;
}

.component .form form fieldset {
    padding-inline: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 0 1rem;
}

.component .form form fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.component .form .fieldsetToggle {
    display: none;
}

.component .form .fieldsetButton {
    display: contents;
}

.component .form .fieldsetButton legend {
    position: relative;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
}

.component .form .fieldsetButton legend span {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    color: rgb(var(--text-color));
}

.component .form .fieldsetButton legend span::after {
    position: relative;
    content: '';

    width: stretch;
    height: .15rem;

    top: 0;
    left: 0;

    border-radius: 1rem;
    background-color: rgb(var(--text-color));
}

.component .form .fieldsetButton legend i {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    transition: 200ms ease-in-out;
}

.component .form .fieldsetButton legend span,
.component .form .fieldsetButton legend span::after,
.component .form .fieldsetButton legend i svg {
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.component .form .fieldsetButton legend:hover span {
    color: rgb(var(--main-color-1));
}

.component .form .fieldsetButton legend:hover span::after {
    background-color: rgb(var(--main-color-1));
}

.component .form .fieldsetButton legend:hover i svg {
    fill: rgb(var(--main-color-1));
}

.component .form .fieldsetContent {
    display: contents;
}

.component .form .fieldsetContent > div {
    max-height: 0;
    opacity: 0;

    pointer-events: none;
    transition: 300ms ease-in-out;
}

.component .form .fieldsetToggle:checked ~ .fieldsetContent > * {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all;
}

.component .form .fieldsetToggle:not(:checked) ~ .fieldsetButton legend i {
    transform: rotateZ(180deg);
}

.component .form .formActions {
    padding-inline: 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 1rem;
    margin-block: 1rem;
    margin-left: auto;
}

.component .form .formActions button {
    margin: 0;
}

.component .list {
    overflow-x: auto;
}

.actions button svg {
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.actions button:hover svg,
.actions button:active svg {
    fill: rgb(var(--main-color-1));
}

.listActions {
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 1rem;
    margin-block: 1rem;
}

.listActions > *:nth-last-child(2) {
    flex-grow: 1;
}

.listActions button {
    margin: 0;
}

.listActions button:only-child {
    margin-left: auto;
}

.listItemMenu {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.listItemMenu input {
    display: none;
}

.listItemMenu label {
    font-size: 2rem;
    display: contents;
    cursor: pointer;
}

.listItemMenu label svg {
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.listItemMenu label:hover svg {
    fill: rgb(var(--main-color-1));
}

.listItemMenu input:checked + label::before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    cursor: default;
}

.listItemMenu ul {    
    position: absolute;
    width: max-content;
    height: max-content;
    right: 75%;
    top: 0;

    transform: translateY(-1.65rem);

    display: flex;
    flex-direction: column;
    gap: .5rem;

    margin: 0;
    padding: .5rem;
    border-radius: 1rem;

    opacity: 0;
    pointer-events: none;

    transition: 200ms ease;
}

.listItemMenu input:checked ~ ul {
    top: 50%;
    right: 100%;

    opacity: 1;
    pointer-events: all;
}

.listItemMenu ul li {
    display: grid;
    justify-content: stretch;
}

.listItemMenu ul li a {
    font-weight: 500;
    margin: 0;
    padding: .5em;
    border-radius: .75em;

    text-decoration: none;
    color: rgb(var(--text-color));

    transition: 300ms ease-in-out;
}

.listItemMenu ul li a:hover {
    background-color: rgb(var(--main-color-1));
    color: white;
}

.QRCode {
    display: grid;
    gap: .75rem;
}

.QRCode > table {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 1rem;
}

.QRCode > table tbody,
.QRCode > table tr {
    display: contents;
}

.QRCode > table tr th {
    text-align: start;
    display: inline-flex;
    justify-content: space-between;
    gap: .5rem;
}

.QRCode > table tr th i {
    all: unset;
}

.QRCode > figure {
    padding: 10px;
    padding-bottom: 8px;

    --thickness: .15rem;
    --color: rgb(var(--text-color));
    --width: 2rem;
    --gradient: #0000 90deg, var(--color) 0;
  
    border: var(--thickness) solid transparent;
    background:
        conic-gradient(from 90deg  at top    var(--thickness) left  var(--thickness),var(--gradient)) 0    0,
        conic-gradient(from 180deg at top    var(--thickness) right var(--thickness),var(--gradient)) 100% 0,
        conic-gradient(from 0deg   at bottom var(--thickness) left  var(--thickness),var(--gradient)) 0    100%,
        conic-gradient(from -90deg at bottom var(--thickness) right var(--thickness),var(--gradient)) 100% 100%;
    background-size: var(--width) var(--width);
    background-origin: border-box;
    background-repeat: no-repeat;
}

.QRCode > figure img {
    width: 300px;
    aspect-ratio: 1 / 1;
    border-radius: .25rem;
    background: rgba(var(--bg-color-1), .5);
}

.nodata {
    margin: 0;
    padding: 0 2rem;

    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .component.pabx header {
        padding: 2rem;
        border-radius: 1rem;
    
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: auto;
        gap: 3rem 0;
    }

    .component .form {
        padding: 1rem 0;
    }

    .component .form .fieldsetButton legend {
        width: min(calc(100vw - 10rem), 350px);
    }

    .info {
        overflow: hidden;
    }

    .info h2 {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .info h2 i {
        display: none;
    }

    .pabx .info {
        grid-column: span 3;
    }

    .component.pabx header th,
    .component.pabx header td {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 425px) {
    .component header {
        gap: 0;
    }
    .pabx .info {
        display: none;
    }
}


/* telephones */

.telephones .list .check {
    display: flex;
}

.telephones .list .check input {
    margin: auto;
    transform: none;
    font-size: 1.5em;
}

.telephones .list .check input:checked {
    cursor: default;
    pointer-events: none;
}


/* audios */

.audios .wrapper {
    padding-top: 1rem;
}

.audios .wrapper > section {
    padding-bottom: 1rem;
}

.audios .wrapper > *:first-child {
    padding-left: 1rem;
}

.audios .list table thead tr th {
    padding-top: .25rem;
}

@media screen and (max-width: 768px) {
    .component.audios .list table colgroup col:first-of-type {
        width: 99%;
    }

    .component.audios .list table colgroup col:nth-last-of-type(2) {
        width: 0;
        display: none;
    }

    .component.audios .list table thead th:nth-last-of-type(2),
    .component.audios .list table thead tr th:nth-last-of-type(2),
    .component.audios .list table tbody tr td:nth-last-of-type(2) {
        display: none;
    }
}


/* uras */

.uras .form .options > div {
    grid-column: 1;
}


/* queues */

.queues .form fieldset:first-of-type > div {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(2, 1fr);
}

.queues .form fieldset:last-of-type > div {
    grid-column: 1;
}


/* report */

.report .selected {
    display: grid;
    gap: .75rem;
}

.report .selected > h3 {
    margin: 0;
}

.report .selected > audio::-webkit-media-controls-panel {
    padding: 0;
}

.report .selected > table {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 1rem;
}

.report .selected > table tbody,
.report .selected > table tr {
    display: contents;
}

.report .selected > table tr th {
    text-align: start;
    display: inline-flex;
    justify-content: space-between;
    gap: .5rem;
}

.report .selected > table tr th i {
    all: unset;
}