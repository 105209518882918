@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap');

:root {
	--bg-color-0: 200, 205, 215;
	--bg-color-1: 235, 240, 250;
	--main-color-0: 70, 90, 100;
	--main-color-1: 35, 140, 175;
	--main-color-2: 140, 175, 35;
	--main-color-3: 175, 140, 40;
	--main-color-4: 175, 35, 70;
	--main-color-5: 140, 70, 140;
	--text-color: 75, 85, 90;
	--input-color: 205, 210, 220;
	--input-shadow-color: 75, 85, 90;
	--placeholder-color: 115, 115, 115;

	font-size: 1em;

	scroll-behavior: smooth;
}

.dark-theme {
	--bg-color-0: 10, 20, 25;
	--bg-color-1: 30, 40, 45;
	--text-color: 170, 180, 190;
	--input-color: 50, 60, 70;
	--input-shadow-color: 5, 10, 20;
}

html {
	width: 100vw;
	min-height: 100vh !important;
	height: 100%;

	background: linear-gradient(to left top, rgb(var(--bg-color-0)), rgb(var(--bg-color-1)));
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;

	overflow: hidden;
}

body, #root {
	width: 100vw;
	min-height: 100vh !important;
	height: 100%;
}

#root {
	overflow: hidden auto;
}

*, * > * {
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;

	font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
	font-smooth: auto;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
	color: rgb(var(--text-color));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body *::-webkit-scrollbar {
	appearance: none;
	width: 1.25rem;
	background: transparent;
}

body *::-webkit-scrollbar-track {
	border-radius: 1rem;
	background: transparent;
	margin-block: 0.6rem;
}

body *::-webkit-scrollbar-thumb {
	border: 0.35em solid transparent;
	border-radius: 1rem;
	margin-block: 0.5rem;
	background-clip: padding-box;

	background-color: rgba(var(--text-color), 0.25);
}

body *:hover::-webkit-scrollbar-thumb {
	background-color: rgba(var(--text-color), 0.5);
}

p {
	overflow-wrap: break-word;
}

p, a, h1, h2, h3, h4, h5, h6, ul, legend {
	margin-bottom: 1em;
	font-weight: normal;
}

a, strong, span {
	white-space: break-spaces;
}

a {
	display: inline-block;
	color: rgb(var(--main-color-0));
}

strong {
	font-weight: 500;
}

span {
	color: rgb(var(--main-color-1));
}

h1 {
	font-size: 2.5em;
}

h2 {
	font-size: 2em;
}

h3 {
	font-size: 1.5em;
}

h4, h5, h6 {
	font-size: 1.25em;
}

ul, ol {
	list-style: none;
}

img.logo {
	font-size: 2.5em;
	height: 1em;
	margin-bottom: 1em;
}

.container {
	padding: 2em;
	border-radius: 2em;
}

button, input, select {
	position: relative;
	border-radius: 1em;
}

.glass {
	--glass-bg-color-0: var(--bg-color-0);
	--glass-bg-color-1: var(--bg-color-1);

    background: linear-gradient(to bottom, rgba(var(--glass-bg-color-0), 1), rgba(var(--glass-bg-color-1), 1));
    outline: solid rgba(var(--bg-color-1), 1) thin;
    box-shadow: 
		0 25px 25px -15px rgba(0, 10, 20, 0.1);
}

.glass.main-color-0 {
	--glass-bg-color-0: var(--main-color-0);
	--glass-bg-color-1: var(--main-color-0);
}

.glass.main-color-1 {
	--glass-bg-color-0: var(--main-color-1);
	--glass-bg-color-1: var(--main-color-1);
}

.glass.main-color-2 {
	--glass-bg-color-0: var(--main-color-2);
	--glass-bg-color-1: var(--main-color-2);
}

.glass.main-color-3 {
	--glass-bg-color-0: var(--main-color-3);
	--glass-bg-color-1: var(--main-color-3);
}

.glass.main-color-4 {
	--glass-bg-color-0: var(--main-color-4);
	--glass-bg-color-1: var(--main-color-4);
}

.glass.main-color-5 {
	--glass-bg-color-0: var(--main-color-5);
	--glass-bg-color-1: var(--main-color-5);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.glass {
		background: linear-gradient(to bottom, rgba(var(--glass-bg-color-0), 0.25), rgba(var(--glass-bg-color-1), 0.5));
		backdrop-filter: blur(.5em);
	}
}

label, input, select {
    font-size: 1em;
}

select {
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-webkit-expand {
	display: none;
}

input,
select,
select:-webkit-autofill,
input:-webkit-autofill {
	--border-color: var(--text-color);
	--border-visibility: 0;

	padding: 1em;

	color: rgb(var(--text-color));
    background: rgba(var(--input-color), 1);
	box-shadow:
		inset 0 10px 15px -10px rgba(var(--input-shadow-color), 0.25),
		inset 0 0 0 2em rgba(var(--input-color), 0.75),
		0 0 0 0.125em rgba(var(--border-color), var(--border-visibility));

	outline-style: solid;
	outline-width: 0.35em;
	outline-offset: -0.1em;
	outline-color: transparent;

	transition: 150ms ease-in-out;
}

input[type="checkbox"], 
input[type="radio"] {
	appearance: none;

	border: solid 0.1em rgba(var(--border-color), 0.25);

	width: 1em;
	height: 1em;

	padding: 0;
	margin: 0;

	transform: translateY(0.15em);

	transition: 200ms ease-in-out;

	cursor: pointer;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
	background-color: rgb(var(--main-color-1));
	box-shadow: 
		inset 0 0 0 0.3em rgb(var(--main-color-1)),
		inset 0 0 0 1em rgb(var(--bg-color-1));
	border: none;
}

input[type="radio"]:checked {
	cursor: default;
}

input[type="checkbox"]:disabled,
input[type="radio"]:disabled {
	pointer-events: none;
	border-color: transparent;
	opacity: 0.75;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
	cursor: pointer;
}

input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label,
input[type="radio"]:checked + label {
	cursor: default;
}

input[type="checkbox"] {
	border-radius: 0.25rem;
}

input:-webkit-autofill {
	-webkit-text-fill-color: rgb(var(--main-color-0));
	font-weight: 600;
}

input:-webkit-autofill[type="password"] {
	font-weight: normal;
}

input:focus, select:focus {
	outline-color: rgba(var(--border-color), 0.25);
	--border-color: var(--main-color-1);
	--border-visibility: 1;
}

select {
	transition: border-radius 150ms ease-in-out;
}

select:focus {
	transition: border-radius 0ms;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;

	box-shadow:
		inset 0 10px 15px -10px rgba(var(--input-shadow-color), 0.25),
		inset 0 0 0 2em rgba(var(--input-color), 0.75),
		inset 0 0.1vmin 0 0.1vmin rgba(var(--border-color), var(--border-visibility));
}

option {
	background-color: rgb(var(--bg-color-1));
}

input[type^="date"]::-webkit-calendar-picker-indicator {
	filter: opacity(.5);
}

.dark-theme input[type^="date"]::-webkit-calendar-picker-indicator {
	filter: opacity(.35) invert();
}

input[value=""] {
    background: rgba(var(--input-color), 0.5);
}

button {
	--btn-color: var(--main-color-1);
	position: relative;
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	min-width: 150px;
	max-width: fit-content;

	padding: 1em 1.5em;
	margin: 1em 0;
	
	font-size: 1em;
	font-weight: 500;
	color: white;
	
	background-color: rgb(var(--btn-color));
	box-shadow: 0 15px 30px -15px rgba(var(--btn-color),0.5);

	transition: 750ms cubic-bezier(0.5, 3, 0, -0.5), background-color 500ms ease, color 500ms ease;
	transform-origin: bottom;
	cursor: pointer;
}

button.full-size {
	min-width: 100%;
}

button.main-color-0 {
	--btn-color: var(--main-color-0);
}

button.main-color-1 {
	--btn-color: var(--main-color-1);
}

button.main-color-2 {
	--btn-color: var(--main-color-2);
}

button.main-color-3 {
	--btn-color: var(--main-color-3);
}

button.main-color-4 {
	--btn-color: var(--main-color-4);
}

button.main-color-5 {
	--btn-color: var(--main-color-5);
}

button:hover {
	transform: scale(0.99) translateY(0.1vh);
	box-shadow: 0 10px 25px -10px rgba(var(--btn-color),0.5);
}

button:active {
	filter: brightness(1.1);
}

button:disabled {
	filter: opacity(0.75);
	transform: none;
	cursor: default;
}

button.transparent {
	color: rgb(var(--btn-color));
	background-color: rgba(var(--btn-color), 0);
	box-shadow: 0 15px 30px -15px rgb(var(--bg-color-0));
	
	font-weight: bold;
}

button.transparent:hover {
	background-color: rgba(var(--btn-color), 0.05);
	box-shadow: 0 10px 25px -10px rgba(var(--bg-color-0));
}

@media screen and (max-width: 1600px) {
	:root {
		font-size: .75em;
	}
}