.main {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 1.5rem !important;
}

.callDisposition, 
.callsPerHour,
.callsPerType,
.callsPerQueue {
    padding: 2rem;
    border-radius: 1rem;
    grid-column: span 12;
}


/* CallDisposition */

.callDisposition h2 {
    font-size: 2.5rem;
    font-weight: 300;
}


.callDisposition > div,
.callDisposition > div > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    grid-auto-flow: row;
    row-gap: 2.5rem;
}

.callDisposition section {
    grid-column: span 1;
}

.callDisposition > div > div:first-of-type section:first-of-type {
    --marker-color: var(--main-color-2);
}

.callDisposition > div > div:first-of-type section:last-of-type {
    --marker-color: var(--main-color-4);
}

.callDisposition > div > div:last-of-type section:first-of-type {
    --marker-color: var(--main-color-3);
}

.callDisposition > div > div:last-of-type section:last-of-type {
    --marker-color: var(--main-color-0);
}

.callDisposition section h3 {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    gap: 1rem;
}

.callDisposition section h3::before {
    content: '';
    width: 1em;
    height: 1em;

    display: inline-block;
    
    border-radius: 1rem;
    border: solid .2em rgb(var(--marker-color));
}

.callDisposition section p {
    font-size: 3rem;

    margin: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 1rem;

    font-weight: 500;
}

.callDisposition section p small {
    font-size: .5em;
    font-weight: normal;
}


/* CallsPerHour */

.callsPerHour {
    padding-left: 0;
    height: fit-content;
}

.callsPerHour .chart {
    min-height: 16rem;
    padding-right: 1rem;
    display: grid;
}

.callsPerHour .chart * {
    min-width: 0;
}

.callsPerHour > h2 {
    font-size: 2.5rem;
    padding: 0 2rem;
}

.callsPerHour .customTooltip {
    padding: 1rem;

    border-radius: 1rem;
    box-shadow: none;
}

.callsPerHour .customTooltip h3 {
    margin-bottom: 1rem;
}

.callsPerHour .customTooltip time {
    margin-bottom: 1rem;
    text-align: end;
    display: inline-block;
    width: 100%;
    margin-bottom: .25rem;
}


/* CallsPerType */

.callsPerType {
    grid-column: span 7;
    display: grid;
    grid-template-rows: 4rem 12rem;
    grid-template-columns: auto 20rem;
}

.callsPerType h2 {
    grid-column: span 1;
    grid-row: span 1;
}

.callsPerType .legend {
    grid-column: span 1;
    grid-row: 2 / span 1;

    display: flex;
    align-items: center;
}

.callsPerType .chart {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    min-height: 16rem;
    min-width: 16rem;
    display: grid;
    max-width: 200px;
    justify-self: end;
}

.callsPerType .chart svg {
    transform: scale(1.25);
}

.callsPerType .chart * {
    min-width: 0;
}

.callsPerType .legend ul li {
    position: relative;
    margin-block: .25rem;

    cursor: pointer;
}

.callsPerType .legend ul li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    height: .5rem;
    transform: translateY(-50%);
    background-color: rgb(var(--marker-color));
}

.callsPerType .legend ul li.active::before {
    outline: solid .15rem rgba(var(--marker-color), .5);
}

.callsPerType .legend ul li:first-child {
    --marker-color: var(--main-color-1);
}

.callsPerType .legend ul li:nth-child(2) {
    --marker-color: var(--main-color-2);
}

.callsPerType .legend ul li:nth-child(3) {
    --marker-color: var(--main-color-3);
}

.callsPerType .legend ul li:last-child {
    --marker-color: var(--main-color-4);
}

.callsPerType .legend ul li span {
    color: rgb(var(--text-color));
    margin-left: 2.5rem;
}

.callsPerType .customTooltip {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: none;
}

.callsPerType .customTooltip h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}


/* CallsPerQueue */

.callsPerQueue {
    grid-column: span 7;
    display: grid;
    grid-template-rows: 4rem 12rem;
    grid-template-columns: auto 20rem;
}

.callsPerQueue h2 {
    grid-column: span 1;
    grid-row: span 1;
}

.callsPerQueue .legend {
    grid-column: span 1;
    grid-row: 2 / span 1;

    display: flex;
    align-items: center;
}

.callsPerQueue .chart {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    min-height: 16rem;
    min-width: 16rem;
    display: grid;
    max-width: 200px;
    justify-self: end;
}

.callsPerQueue .chart svg {
    transform: scale(1.25);
}

.callsPerQueue .chart * {
    min-width: 0;
}

.callsPerQueue .legend ul li {
    position: relative;
    margin-block: .25rem;

    cursor: pointer;
}

.callsPerQueue .legend ul li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    height: .5rem;
    transform: translateY(-50%);
    background-color: rgb(var(--marker-color));
}

.callsPerQueue .legend ul li.active::before {
    outline: solid .15rem rgba(var(--marker-color), .5);
}

.callsPerQueue .legend ul li:first-child {
    --marker-color: var(--main-color-1);
}

.callsPerQueue .legend ul li:nth-child(2) {
    --marker-color: var(--main-color-2);
}

.callsPerQueue .legend ul li:nth-child(3) {
    --marker-color: var(--main-color-3);
}

.callsPerQueue .legend ul li:nth-child(4) {
    --marker-color: var(--main-color-4);
}

.callsPerQueue .legend ul li:last-child {
    --marker-color: var(--main-color-5);
}

.callsPerQueue .legend ul li span {
    color: rgb(var(--text-color));
    margin-left: 2.5rem;
}

.callsPerQueue .customTooltip {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: none;
}

.callsPerQueue .customTooltip h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}


/* Balance */

.balance {
    grid-column: 8 / span 5;
    grid-row:  3 / span 2;

    display: grid;
    grid-template-rows: repeat(4, 10rem);
    row-gap: 1.5rem;
}

.balance * {
    min-width: 0;
}

.balance section {
    --status-color: var(--main-color-2);

    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: max-content auto;

    padding: 2rem;
    border-radius: 1rem;

    background: 
        linear-gradient(to bottom, rgba(var(--bg-color-0), .25), rgba(var(--bg-color-1), .5)),
        rgb(var(--status-color));
    background-blend-mode: luminosity;
    outline-color: rgba(var(--status-color), .5);
}

.balance section[data-value="0"] {
    --status-color: var(--main-color-3);
}

.balance section h3 {
    grid-row: 1;
    grid-column: span 1;
}

.balance section p {
    grid-row: 2;
    grid-column: span 1;

    margin: 0;

    font-size: 2.5rem;
}

.balance section * {
    color: white;
}

.balance section i {
    grid-column: 2;
    grid-row: span 2;

    display: flex;
    justify-content: stretch;
    align-items: stretch;

    padding-block: .5rem;
}

.balance section i svg {
    margin-left: auto;
    height: 100%;
    width: auto;
}

.balance table {
    display: contents;
}

.balance tbody {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: minmax(min-content, auto) auto;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
}

.balance tbody[class="glass"] {
    border-radius: 1rem;
}

.balance tr {
    display: contents;
}

.balance tbody tr th,
.balance tbody tr td {
    font-size: 1.5rem;
}

.balance tbody tr td {
    text-align: end;
}

.balance tbody tr th {
    text-align: start;
    font-weight: normal;
}

.balance tbody tr th {
    overflow-wrap: break-word;
}

@media screen and (max-width: 768px) {
    .callDisposition,
    .callsPerHour,
    .callsPerType,
    .callsPerQueue,
    .balance {
        grid-column: span 12;
        grid-row: span 1;
    }
}

@media screen and (max-width: 1024px) {
    .callsPerType,
    .callsPerQueue {
        display: grid;
        grid-template-rows: 4rem 16rem auto;
        grid-template-columns: minmax(16rem, 1fr);
        grid-template-areas: 
            "title"
            "chart"
            "legend";
    }

    .callsPerType h2,
    .callsPerQueue h2 {
        grid-area: title;
    }

    .callsPerType .chart,
    .callsPerQueue .chart {
        grid-area: chart;
        margin: auto;
    }

    .callsPerType .chart svg,
    .callsPerQueue .chart svg {
        transform: none;
    }
    
    .callsPerType .legend,
    .callsPerQueue .legend {
        grid-area: legend;
    }

    .callsPerType .legend ul,
    .callsPerQueue .legend ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }

    .callsPerType .legend ul li span,
    .callsPerQueue .legend ul li span {
        margin: 0 1rem;
    }

    .callsPerType .legend ul li::before,
    .callsPerQueue .legend ul li::before {
        width: .5rem;
    }
    
    .balance tbody {
        padding: 0 1rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .balance tbody[class="glass"] {
        background: none;
        outline: none;
        box-shadow: none;
    }

    .balance section i {
        display: none;
    }
}