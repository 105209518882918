.progress {
	position: fixed;
	z-index: 999999;

	width: 100vw;
	height: 0;
	top: 0;
	left: 0;

	background: transparent;
}

.progress::-webkit-progress-bar {
    background: transparent;
}

.progress::-webkit-progress-value {
    background: transparent;
}

.progress::before {
	content: "";
	position: absolute;

	width: 0vw;
	height: 0.25vh;
	top: 0;
	left: 0;
	z-index: 1;

	background-color: rgb(var(--main-color-1));

    transition: width 0ms, background-color 0ms;
}

.progress[value^="0."]::before {
    transition: width 1000000ms cubic-bezier(0, 1, 0, .5);
    width: 99vw;
}

.progress[value="1"]::before {
    transition: background-color 0ms 1000ms linear, width 500ms 0ms ease;
	background-color: rgb(var(--main-color-1), 0);
    width: 100vw;
}

.progress[value^="0."]::after {
	position: absolute;
	content: '';
	z-index: 999999;

	width: 100vw;
	height: 100vw;
	top: 0;
	left: 0;

	background: transparent;

	cursor: progress;
}