.table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    table-layout: auto;
}

.table tbody tr:nth-child(2n) {
    background: linear-gradient(to left, rgb(var(--bg-color-0)), transparent);
}

.table tbody tr:nth-child(2n+1) {
    background: linear-gradient(to right, rgb(var(--bg-color-1)), transparent);
}

.table td,
.table th {
    padding: 1rem;
}

.table thead tr > *:first-child,
.table tbody tr > *:first-child,
.table tfoot tr > *:first-child,
.table tfoot tr > *:first-child {
    padding-left: 2rem;
}

.table thead tr > *:last-child,
.table tbody tr > *:last-child,
.table tfoot tr > *:last-child,
.table tfoot tr > *:last-child {
    padding-right: 2rem;
}

.table tbody tr > *:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.table tbody tr > *:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.table tfoot tr > * {
    font-weight: bold;
}

.table button {
    all: initial;

    font-size: 2rem;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
}

.table button svg {
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.table button:hover svg {
    fill: rgb(var(--main-color-1));
}