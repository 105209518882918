.main {
    min-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "form article";
}

.login {
    border-radius: 0;
    grid-area: form;
    justify-self: center;
    align-self: center;
}

.advertising {
    border-radius: 0;
    padding: 1.25rem 4rem;
    display: flex;
    align-items: center;

    background: linear-gradient(to left top, rgb(var(--bg-color-0)), rgb(var(--bg-color-1))), url('../../../assets/images/background/advertising.jpg');
	background-size: cover;
	background-position: center;
	background-blend-mode: soft-light;
}

.form button ~ button {
    margin-left: 1rem;
}

.form a {
    margin: 0.5rem 0 1rem 1rem;
}

.ul li::before {
    content: '✓';
    font-size: 1em;
    color: rgb(var(--main-color-2));
    padding-right: 1rem;
}

@media only screen and (max-width: 768px) {
    .main {
        grid-template-columns: minmax(22rem, 1fr);
    }

    .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 22rem;
    }

    .form {
        display: contents;
    }

    .form fieldset {
        width: 100%;
    }

    .form button {
        margin: 1rem 0;
    }

    .form a {
        margin: 2em 0 4em 0;
    }

    .main > .advertising {
        display: none;
        user-select: none;
        pointer-events: none;
    }

    button.btnSignin {
        min-width: 150px;
    }

    .form a {
        display: block;
    }
}

@media only screen and (min-width: 769px) {
    button.btnSignup {
        display: none;
    }
}