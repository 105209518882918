.dialog {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: contents;

	isolation: isolate;

	z-index: 999;
}

.dialog .card {
	position: absolute;
	z-index: 9999;
	
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);

	min-width: 350px;
	max-width: 450px;
	margin: auto;
	padding: 2em;
	border-radius: 2em;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.dialog[open=""]::before {
	content: '';
	position: fixed;
	z-index: 9999;

	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background: rgba(15, 15, 15, .3);

	backdrop-filter: blur(1em);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.dialog[open=""]::before{
		background: none;
		backdrop-filter: blur(1em);
	}
}

.dialog:not([open=""]) {
	display: none;
}

.dialog .card button {
	margin-bottom: 0;
	align-self: flex-end;
}

.dialog .card button ~ button {
	margin-left: 1rem;
}

.dialog .card .actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.dialog .card .form {
	margin: 1.5em auto 0 auto;
	width: 100%;
}

.dialog .card .form > div {
	width: 100%;
}

.dialog .card .form ~ .actions button {
	margin-top: 0;
}