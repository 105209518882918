.form {
    overflow: visible;
}

.form form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    gap: 1rem;
}

.form form fieldset {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 0 1rem;
}

.form form fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.form .fieldsetToggle {
    display: none;
}

.form .fieldsetButton {
    display: contents;
}

.form .fieldsetButton legend {
    position: relative;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
}

.form .fieldsetButton legend span {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    color: rgb(var(--text-color));
}

.form .fieldsetButton legend span::after {
    position: relative;
    content: '';

    width: stretch;
    height: .15rem;

    top: 0;
    left: 0;

    border-radius: 1rem;
    background-color: rgb(var(--text-color));
}

.form .fieldsetButton legend i {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    transition: 200ms ease-in-out;
}

.form .fieldsetButton legend span,
.form .fieldsetButton legend span::after,
.form .fieldsetButton legend i svg {
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.form .fieldsetButton legend:hover span {
    color: rgb(var(--main-color-1));
}

.form .fieldsetButton legend:hover span::after {
    background-color: rgb(var(--main-color-1));
}

.form .fieldsetButton legend:hover i svg {
    fill: rgb(var(--main-color-1));
}

.form .fieldsetContent {
    display: contents;
}

.form .fieldsetContent > div {
    max-height: 0;
    opacity: 0;

    pointer-events: none;
    transition: 300ms ease-in-out;
}

.form .fieldsetToggle:checked ~ .fieldsetContent > * {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all;
}

.form .fieldsetToggle:checked ~ .fieldsetButton legend i {
    transform: rotateZ(180deg);
}

.form .formActions {
    padding-inline: 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 1rem;
    margin-block: 1rem;
    margin-left: auto;
}

.form .formActions button {
    margin: 0;
}

.form fieldset:last-of-type > div {
    grid-column: 1;
}

.nodata {
    margin: 0;
    padding: 0 2rem;

    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}