@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap);
:root {
	--bg-color-0: 200, 205, 215;
	--bg-color-1: 235, 240, 250;
	--main-color-0: 70, 90, 100;
	--main-color-1: 35, 140, 175;
	--main-color-2: 140, 175, 35;
	--main-color-3: 175, 140, 40;
	--main-color-4: 175, 35, 70;
	--main-color-5: 140, 70, 140;
	--text-color: 75, 85, 90;
	--input-color: 205, 210, 220;
	--input-shadow-color: 75, 85, 90;
	--placeholder-color: 115, 115, 115;

	font-size: 1em;

	scroll-behavior: smooth;
}

.dark-theme {
	--bg-color-0: 10, 20, 25;
	--bg-color-1: 30, 40, 45;
	--text-color: 170, 180, 190;
	--input-color: 50, 60, 70;
	--input-shadow-color: 5, 10, 20;
}

html {
	width: 100vw;
	min-height: 100vh !important;
	height: 100%;

	background: -webkit-gradient(linear, right bottom, left top, from(rgb(200, 205, 215)), to(rgb(235, 240, 250)));

	background: linear-gradient(to left top, rgb(200, 205, 215), rgb(235, 240, 250));

	background: -webkit-gradient(linear, right bottom, left top, from(rgb(var(--bg-color-0))), to(rgb(var(--bg-color-1))));

	background: linear-gradient(to left top, rgb(var(--bg-color-0)), rgb(var(--bg-color-1)));
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;

	overflow: hidden;
}

body, #root {
	width: 100vw;
	min-height: 100vh !important;
	height: 100%;
}

#root {
	overflow: hidden auto;
}

*, * > * {
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;

	font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
	font-smooth: auto;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
	color: rgb(75, 85, 90);
	color: rgb(var(--text-color));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body *::-webkit-scrollbar {
	-webkit-appearance: none;
	        appearance: none;
	width: 1.25rem;
	background: transparent;
}

body *::-webkit-scrollbar-track {
	border-radius: 1rem;
	background: transparent;
	margin-block: 0.6rem;
}

body *::-webkit-scrollbar-thumb {
	border: 0.35em solid transparent;
	border-radius: 1rem;
	margin-block: 0.5rem;
	background-clip: padding-box;

	background-color: rgba(75, 85, 90, 0.25);

	background-color: rgba(var(--text-color), 0.25);
}

body *:hover::-webkit-scrollbar-thumb {
	background-color: rgba(75, 85, 90, 0.5);
	background-color: rgba(var(--text-color), 0.5);
}

p {
	overflow-wrap: break-word;
}

p, a, h1, h2, h3, h4, h5, h6, ul, legend {
	margin-bottom: 1em;
	font-weight: normal;
}

a, strong, span {
	white-space: break-spaces;
}

a {
	display: inline-block;
	color: rgb(70, 90, 100);
	color: rgb(var(--main-color-0));
}

strong {
	font-weight: 500;
}

span {
	color: rgb(35, 140, 175);
	color: rgb(var(--main-color-1));
}

h1 {
	font-size: 2.5em;
}

h2 {
	font-size: 2em;
}

h3 {
	font-size: 1.5em;
}

h4, h5, h6 {
	font-size: 1.25em;
}

ul, ol {
	list-style: none;
}

img.logo {
	font-size: 2.5em;
	height: 1em;
	margin-bottom: 1em;
}

.container {
	padding: 2em;
	border-radius: 2em;
}

button, input, select {
	position: relative;
	border-radius: 1em;
}

.glass {
	--glass-bg-color-0: var(--bg-color-0);
	--glass-bg-color-1: var(--bg-color-1);

    background: -webkit-gradient(linear, left top, left bottom, from(rgba(var(--glass-bg-color-0), 1)), to(rgba(var(--glass-bg-color-1), 1)));

    background: linear-gradient(to bottom, rgba(var(--glass-bg-color-0), 1), rgba(var(--glass-bg-color-1), 1));
    outline: solid rgba(235, 240, 250, 1) thin;
    outline: solid rgba(var(--bg-color-1), 1) thin;
    box-shadow: 
		0 25px 25px -15px rgba(0, 10, 20, 0.1);
}

.glass.main-color-0 {
	--glass-bg-color-0: var(--main-color-0);
	--glass-bg-color-1: var(--main-color-0);
}

.glass.main-color-1 {
	--glass-bg-color-0: var(--main-color-1);
	--glass-bg-color-1: var(--main-color-1);
}

.glass.main-color-2 {
	--glass-bg-color-0: var(--main-color-2);
	--glass-bg-color-1: var(--main-color-2);
}

.glass.main-color-3 {
	--glass-bg-color-0: var(--main-color-3);
	--glass-bg-color-1: var(--main-color-3);
}

.glass.main-color-4 {
	--glass-bg-color-0: var(--main-color-4);
	--glass-bg-color-1: var(--main-color-4);
}

.glass.main-color-5 {
	--glass-bg-color-0: var(--main-color-5);
	--glass-bg-color-1: var(--main-color-5);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.glass {
		background: -webkit-gradient(linear, left top, left bottom, from(rgba(var(--glass-bg-color-0), 0.25)), to(rgba(var(--glass-bg-color-1), 0.5)));
		background: linear-gradient(to bottom, rgba(var(--glass-bg-color-0), 0.25), rgba(var(--glass-bg-color-1), 0.5));
		-webkit-backdrop-filter: blur(.5em);
		        backdrop-filter: blur(.5em);
	}
}

label, input, select {
    font-size: 1em;
}

select {
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-webkit-expand {
	display: none;
}

input,
select,
select:-webkit-autofill,
input:-webkit-autofill {
	--border-color: var(--text-color);
	--border-visibility: 0;

	padding: 1em;

	color: rgb(75, 85, 90);

	color: rgb(var(--text-color));
    background: rgba(205, 210, 220, 1);
    background: rgba(var(--input-color), 1);
	box-shadow:
		inset 0 10px 15px -10px rgba(75, 85, 90, 0.25),
		inset 0 0 0 2em rgba(205, 210, 220, 0.75),
		0 0 0 0.125em rgba(var(--border-color), var(--border-visibility));
	box-shadow:
		inset 0 10px 15px -10px rgba(var(--input-shadow-color), 0.25),
		inset 0 0 0 2em rgba(var(--input-color), 0.75),
		0 0 0 0.125em rgba(var(--border-color), var(--border-visibility));

	outline-style: solid;
	outline-width: 0.35em;
	outline-offset: -0.1em;
	outline-color: transparent;

	-webkit-transition: 150ms ease-in-out;

	transition: 150ms ease-in-out;
}

input[type="checkbox"], 
input[type="radio"] {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;

	border: solid 0.1em rgba(var(--border-color), 0.25);

	width: 1em;
	height: 1em;

	padding: 0;
	margin: 0;

	-webkit-transform: translateY(0.15em);

	        transform: translateY(0.15em);

	-webkit-transition: 200ms ease-in-out;

	transition: 200ms ease-in-out;

	cursor: pointer;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
	background-color: rgb(35, 140, 175);
	background-color: rgb(var(--main-color-1));
	box-shadow: 
		inset 0 0 0 0.3em rgb(35, 140, 175),
		inset 0 0 0 1em rgb(235, 240, 250);
	box-shadow: 
		inset 0 0 0 0.3em rgb(var(--main-color-1)),
		inset 0 0 0 1em rgb(var(--bg-color-1));
	border: none;
}

input[type="radio"]:checked {
	cursor: default;
}

input[type="checkbox"]:disabled,
input[type="radio"]:disabled {
	pointer-events: none;
	border-color: transparent;
	opacity: 0.75;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
	cursor: pointer;
}

input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label,
input[type="radio"]:checked + label {
	cursor: default;
}

input[type="checkbox"] {
	border-radius: 0.25rem;
}

input:-webkit-autofill {
	-webkit-text-fill-color: rgb(70, 90, 100);
	-webkit-text-fill-color: rgb(var(--main-color-0));
	font-weight: 600;
}

input:-webkit-autofill[type="password"] {
	font-weight: normal;
}

input:focus, select:focus {
	outline-color: rgba(var(--border-color), 0.25);
	--border-color: var(--main-color-1);
	--border-visibility: 1;
}

select {
	-webkit-transition: border-radius 150ms ease-in-out;
	transition: border-radius 150ms ease-in-out;
}

select:focus {
	-webkit-transition: border-radius 0ms;
	transition: border-radius 0ms;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;

	box-shadow:
		inset 0 10px 15px -10px rgba(75, 85, 90, 0.25),
		inset 0 0 0 2em rgba(205, 210, 220, 0.75),
		inset 0 0.1vmin 0 0.1vmin rgba(var(--border-color), var(--border-visibility));

	box-shadow:
		inset 0 10px 15px -10px rgba(var(--input-shadow-color), 0.25),
		inset 0 0 0 2em rgba(var(--input-color), 0.75),
		inset 0 0.1vmin 0 0.1vmin rgba(var(--border-color), var(--border-visibility));
}

option {
	background-color: rgb(235, 240, 250);
	background-color: rgb(var(--bg-color-1));
}

input[type^="date"]::-webkit-calendar-picker-indicator {
	-webkit-filter: opacity(.5);
	        filter: opacity(.5);
}

.dark-theme input[type^="date"]::-webkit-calendar-picker-indicator {
	-webkit-filter: opacity(.35) invert();
	        filter: opacity(.35) invert();
}

input[value=""] {
    background: rgba(205, 210, 220, 0.5);
    background: rgba(var(--input-color), 0.5);
}

button {
	--btn-color: var(--main-color-1);
	position: relative;
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	min-width: 150px;
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;

	padding: 1em 1.5em;
	margin: 1em 0;
	
	font-size: 1em;
	font-weight: 500;
	color: white;
	
	background-color: rgb(var(--btn-color));
	box-shadow: 0 15px 30px -15px rgba(var(--btn-color),0.5);

	-webkit-transition: 750ms cubic-bezier(0.5, 3, 0, -0.5), background-color 500ms ease, color 500ms ease;

	transition: 750ms cubic-bezier(0.5, 3, 0, -0.5), background-color 500ms ease, color 500ms ease;
	-webkit-transform-origin: bottom;
	        transform-origin: bottom;
	cursor: pointer;
}

button.full-size {
	min-width: 100%;
}

button.main-color-0 {
	--btn-color: var(--main-color-0);
}

button.main-color-1 {
	--btn-color: var(--main-color-1);
}

button.main-color-2 {
	--btn-color: var(--main-color-2);
}

button.main-color-3 {
	--btn-color: var(--main-color-3);
}

button.main-color-4 {
	--btn-color: var(--main-color-4);
}

button.main-color-5 {
	--btn-color: var(--main-color-5);
}

button:hover {
	-webkit-transform: scale(0.99) translateY(0.1vh);
	        transform: scale(0.99) translateY(0.1vh);
	box-shadow: 0 10px 25px -10px rgba(var(--btn-color),0.5);
}

button:active {
	-webkit-filter: brightness(1.1);
	        filter: brightness(1.1);
}

button:disabled {
	-webkit-filter: opacity(0.75);
	        filter: opacity(0.75);
	-webkit-transform: none;
	        transform: none;
	cursor: default;
}

button.transparent {
	color: rgb(var(--btn-color));
	background-color: rgba(var(--btn-color), 0);
	box-shadow: 0 15px 30px -15px rgb(200, 205, 215);
	box-shadow: 0 15px 30px -15px rgb(var(--bg-color-0));
	
	font-weight: bold;
}

button.transparent:hover {
	background-color: rgba(var(--btn-color), 0.05);
	box-shadow: 0 10px 25px -10px rgba(200, 205, 215);
	box-shadow: 0 10px 25px -10px rgba(var(--bg-color-0));
}

@media screen and (max-width: 1600px) {
	:root {
		font-size: .75em;
	}
}
.styles_progress__1a7VN {
	position: fixed;
	z-index: 999999;

	width: 100vw;
	height: 0;
	top: 0;
	left: 0;

	background: transparent;
}

.styles_progress__1a7VN::-webkit-progress-bar {
    background: transparent;
}

.styles_progress__1a7VN::-webkit-progress-value {
    background: transparent;
}

.styles_progress__1a7VN::before {
	content: "";
	position: absolute;

	width: 0vw;
	height: 0.25vh;
	top: 0;
	left: 0;
	z-index: 1;

	background-color: rgb(var(--main-color-1));

    -webkit-transition: width 0ms, background-color 0ms;

    transition: width 0ms, background-color 0ms;
}

.styles_progress__1a7VN[value^="0."]::before {
    -webkit-transition: width 1000000ms cubic-bezier(0, 1, 0, .5);
    transition: width 1000000ms cubic-bezier(0, 1, 0, .5);
    width: 99vw;
}

.styles_progress__1a7VN[value="1"]::before {
    -webkit-transition: background-color 0ms 1000ms linear, width 500ms 0ms ease;
    transition: background-color 0ms 1000ms linear, width 500ms 0ms ease;
	background-color: rgb(var(--main-color-1), 0);
    width: 100vw;
}

.styles_progress__1a7VN[value^="0."]::after {
	position: absolute;
	content: '';
	z-index: 999999;

	width: 100vw;
	height: 100vw;
	top: 0;
	left: 0;

	background: transparent;

	cursor: progress;
}

.styles_main__3VGoq {
    height: 100vh;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.styles_main__3VGoq h1 {
    display: inline-block !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    text-align: center !important;
}

header ~ .styles_main__3VGoq {
    height: calc(100vh - 7.5rem);
}

header ~ .styles_main__3VGoq h1 {
    padding-bottom: 7.5rem;
}
.styles_formControl__3DCLu {
	min-width: 350px;

	display: grid;
	grid-template-rows: minmax(1rem, auto) minmax(3rem, auto) minmax(1rem, auto);
	grid-template-columns: auto;
	grid-row-gap: 0.5em;
	row-gap: 0.5em;

	margin-bottom: 1em;

}

.styles_formControl__3DCLu label {
	padding-left: 1em;
	text-transform: uppercase;
	font-weight: bold;
}

.styles_formControl__3DCLu .styles_input__1FsOa:not([type="checkbox"]):not([type="radio"]) {
	height: 3.125rem;
}

.styles_formControl__3DCLu .styles_select__2w7Fd {
	position: relative;
	display: grid;
	max-width: 100%;

	border-radius: 1em;
}

.styles_formControl__3DCLu .styles_select__2w7Fd select {
	display: none;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_input__1FsOa {
	--border-color: var(--text-color);
	--border-visibility: 0;

	display: block;
	position: relative;
	margin: 0;
	padding: 1em;
	padding-right: 2.5em;

	border-radius: inherit;

	text-transform: none;
	font-weight: normal;
	color: rgb(var(--text-color));
	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

    background: rgba(var(--input-color), 1);
	box-shadow:
		inset 0 10px 15px -10px rgba(var(--text-color), 0.25),
		inset 0 0 0 2em rgba(var(--input-color), 0.75),
		0 0 0 0.125em rgba(var(--border-color), var(--border-visibility));

	outline-style: solid;
	outline-width: 0.35em;
	outline-offset: -0.1em;
	outline-color: transparent;

	-webkit-transition: 150ms ease-in-out;

	transition: 150ms ease-in-out;
	cursor: pointer;
}

.styles_formControl__3DCLu .styles_toggle__zxRnv {
	display: none;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_value__2Uh97[value=""] ~ .styles_input__1FsOa {
	color: rgb(var(--placeholder-color));
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_toggle__zxRnv:checked ~ .styles_input__1FsOa {
	outline-color: rgba(var(--border-color), 0.25);
	--border-color: var(--main-color-1);
	--border-visibility: 1;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_input__1FsOa::before {
	content: '';
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: transparent;

	isolation: isolate;
	z-index: 1;

	cursor: default;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_toggle__zxRnv:checked ~ .styles_input__1FsOa::before {
	display: block;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_input__1FsOa::after {
	font-size: .75rem;
	content: '';
	position: absolute;
	width: 1em;
	height: 1em;
	right: 1.5em;
	top: calc(50% - .2em);
	
	background-color: rgb(var(--placeholder-color));;
	-webkit-clip-path: polygon(10% 0, 90% 0, 100% 10%, 100% 20%, 55% 60%, 45% 60%, 0 20%, 0 10%);;
	        clip-path: polygon(10% 0, 90% 0, 100% 10%, 100% 20%, 55% 60%, 45% 60%, 0 20%, 0 10%);

	-webkit-transform-origin: 50% 25%;

	        transform-origin: 50% 25%;
	-webkit-transition: 200ms ease-in-out;
	transition: 200ms ease-in-out;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_toggle__zxRnv:checked ~ ::after {
	-webkit-transform: rotateZ(180deg);
	        transform: rotateZ(180deg);
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_options__3TSJ9 {
	position: absolute;
	top: 0;
	margin: 0;
	padding: 0;
	padding-top: 1em;
	border-radius: 1em;


	width: 100%;
	opacity: 0;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	max-height: 0;
	overflow-x: hidden;
	overflow-y: hidden;
	
	z-index: 1;
	pointer-events: none;
	
	-webkit-transition: 200ms ease-in-out, max-height 200ms 200ms ease-in-out;
	
	transition: 200ms ease-in-out, max-height 200ms 200ms ease-in-out;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_optionsList__2uFpl {
	padding: 0 0.5em;
	padding-bottom: 1em;
	position: relative;
	list-style: auto;
	margin: 0;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	pointer-events: none;

	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	max-height: 8.5em;
	overflow-x: hidden;
	overflow-y: auto;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_optionsList__2uFpl li {
	display: block;
	padding: 0.25em 0.5em;
	border-radius: 0.5em;

	-webkit-transition: 100ms 25ms ease-in-out, color 0ms 25ms;

	transition: 100ms 25ms ease-in-out, color 0ms 25ms;

	font-weight: 500;
	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	cursor: pointer;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_optionsList__2uFpl li:hover {
	background-color: rgba(var(--main-color-1), 0.75);

	-webkit-transition: 100ms ease-in-out, color 25ms 25ms;

	transition: 100ms ease-in-out, color 25ms 25ms;

	color: white;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_optionsList__2uFpl li.styles_disabledOption__2I7V7 {
	font-weight: normal;
	cursor: default;

	color: rgb(var(--placeholder-color));
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_optionsList__2uFpl li.styles_disabledOption__2I7V7:hover {
	font-weight: normal;
	background-color: rgb(var(--placeholder-color));
	color: white;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_toggle__zxRnv:checked ~ .styles_options__3TSJ9 {
	top: calc(100% - 0.5em);
	
	opacity: 1;
	max-height: 12.5em;
	
	-webkit-transition: 200ms ease-in-out, max-height 0ms;
	
	transition: 200ms ease-in-out, max-height 0ms;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_toggle__zxRnv:checked ~ .styles_options__3TSJ9 .styles_optionsList__2uFpl {
	pointer-events: all;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_options__3TSJ9 .styles_searchbox__M0JoI {
	padding-right: 1em;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_options__3TSJ9 .styles_searchbox__M0JoI input {
	width: 100%;
	padding: 0.5em;
	margin: 0.5em;
	margin-right: 0;
	border-radius: 0.5em;

	background-color: rgba(var(--input-color));

	outline: none;
	box-shadow: 
		inset 0 10px 15px -10px rgba(var(--text-color), 0.25),
		inset 0 0 0 .1em rgba(var(--border-color), 0.1);

	pointer-events: all;
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_options__3TSJ9 .styles_searchbox__M0JoI input:focus {
	box-shadow: 
		inset 0 10px 15px -10px rgba(var(--text-color), 0.25),
		inset 0 0 0 .25em rgba(var(--border-color), 0.25),
		inset 0 0 0 .1em rgba(var(--border-color), 0.25);
}

.styles_formControl__3DCLu .styles_select__2w7Fd .styles_options__3TSJ9 .styles_optionsList__2uFpl::-webkit-scrollbar-track {
	margin: 0 !important;
	margin-bottom: 1em !important;
}

.styles_formControl__3DCLu .styles_radio__3e8AO {
	display: flex;
	flex-flow: wrap;
	padding: 0;
	padding-left: 1em;
	padding-top: 0.25em;
	grid-gap: .5em 1em;
	gap: .5em 1em;
	margin: 0;
}

.styles_formControl__3DCLu .styles_checkboxes__3CMT2 li .styles_label__A3e3i,
.styles_formControl__3DCLu .styles_radio__3e8AO li .styles_label__A3e3i {
	position: relative;
	margin-right: 0.5em;
	padding-left: 0.5em;

	text-transform: none;
	font-weight: 500;

	-webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);

	transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_formControl__3DCLu .styles_checkboxes__3CMT2 .styles_input__1FsOa:not(:disabled):hover ~ .styles_label__A3e3i,
.styles_formControl__3DCLu .styles_radio__3e8AO .styles_input__1FsOa:not(:disabled):not(:checked):hover ~ .styles_label__A3e3i {
	opacity: 1;
	width: 100%;

	color: rgb(var(--main-color-1));
}

.styles_formControl__3DCLu .styles_radio__3e8AO li input:disabled ~ .styles_label__A3e3i {
	opacity: 0.5 !important;
}

.styles_formControl__3DCLu .styles_radio__3e8AO li input:disabled ~ .styles_label__A3e3i::after {
	all: unset;
}

.styles_formControl__3DCLu .styles_checkboxes__3CMT2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, auto));
	grid-gap: .5rem;
	gap: .5rem;
}

.styles_formControl__3DCLu .styles_checkboxes__3CMT2 li {
	display: block;
}

.styles_formControl__3DCLu .styles_password__1WJ-l {
	position: relative;

	isolation: isolate;
}

.styles_formControl__3DCLu .styles_password__1WJ-l .styles_input__1FsOa {
	padding-right: 3.5em;

	width: 100%;
}

.styles_formControl__3DCLu .styles_password__1WJ-l .styles_toggleIcon__2yURA {
	font-size: .85em;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 3.5em;
	height: calc(100% - .5em);
	background-color: transparent;

	margin: .25em;

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 1;
}

.styles_formControl__3DCLu .styles_password__1WJ-l .styles_toggleIcon__2yURA::before {
	content: '';
	position: absolute;

	width: 1.5em;
	height: 1.5em;

	border-radius: 100% 0;

	background-color: transparent;
	box-shadow: inset 0.125em 0.125em 0 .125em rgb(var(--placeholder-color));

	-webkit-transition: 50ms ease-out;

	transition: 50ms ease-out;

	-webkit-transform: rotateZ(45deg);

	        transform: rotateZ(45deg);
}

.styles_formControl__3DCLu .styles_password__1WJ-l .styles_toggleIcon__2yURA::after {
	content: '';
	position: absolute;

	width: .55em;
	height: .55em;

	background-color: rgb(var(--placeholder-color));

	border-radius: 100%;

	-webkit-transition: 50ms ease-in, border-radius 0ms 50ms;

	transition: 50ms ease-in, border-radius 0ms 50ms;
}

.styles_formControl__3DCLu .styles_password__1WJ-l .styles_toggle__zxRnv:checked ~ .styles_toggleIcon__2yURA::before {
	height: 0.25em;
	border-radius: 0;

	width: 2em;

	background: rgb(var(--placeholder-color));

	-webkit-transform: rotateZ(70deg) rotateX(90deg) rotateY(-90deg);

	        transform: rotateZ(70deg) rotateX(90deg) rotateY(-90deg);

	-webkit-transition: 50ms ease-in;

	transition: 50ms ease-in;
}

.styles_formControl__3DCLu .styles_password__1WJ-l .styles_toggle__zxRnv:checked ~ .styles_toggleIcon__2yURA::after {
	width: 2em;
	height: 0.225em;

	border-radius: 0;
	border: none;

	-webkit-transition: 50ms ease-in, border-radius 0ms 50ms;

	transition: 50ms ease-in, border-radius 0ms 50ms;
}

.styles_formControl__3DCLu .styles_file__3D7y2 {
	position: relative;
	border-radius: 1rem;
	background-clip: border-box;
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_input__1FsOa {
	display: none;
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_filename__3-nUM {
	width: 100%;
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_button__2NDGD {
	--shadow-color: var(--input-shadow-color);
	--light-color: var(--bg-color-1);

	position: absolute;
	top: 0;
	right: 0;

	height: 100%;
	aspect-ratio: 1.5 / 1;

	display: flex;
	justify-content: center;
	align-items: center;

	background: -webkit-gradient(linear, left top, right top, from(transparent), to(rgb(var(--input-color))));

	background: linear-gradient(to right, transparent, rgb(var(--input-color)));
	box-shadow:
		inset -.5rem -.25rem .25rem -.5rem rgba(var(--shadow-color), .3),
		inset -.5rem 0 .25rem -.5rem rgba(var(--shadow-color), .3),
		inset -.5rem .25rem .25rem -.5rem rgba(var(--shadow-color), .3),
		inset -.5rem 0 .35rem -.5rem rgb(var(--input-color)),
		inset -.5rem 0 .5rem -.5rem rgb(var(--light-color));

	border-radius: inherit;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-right: .0125rem solid rgba(var(--input-color), 1);	

	z-index: 1;
	cursor: pointer;

	-webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);

	transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_filename__3-nUM:disabled ~ .styles_button__2NDGD {
	width: 100%;
	padding-right: 1em;
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_filename__3-nUM:disabled ~ .styles_button__2NDGD > *{
	margin-left: auto;
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_button__2NDGD:hover {
	--light-color: var(--main-color-1);
	--shadow-color: var(--main-color-1);
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_button__2NDGD svg {
	-webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
	transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_button__2NDGD:hover svg {
	fill: rgb(var(--main-color-1));
}

.styles_formControl__3DCLu.styles_invalid__r0CLQ .styles_file__3D7y2 .styles_button__2NDGD:hover svg {
	fill: rgb(var(--main-color-4));
}

.styles_formControl__3DCLu.styles_invalid__r0CLQ .styles_file__3D7y2 .styles_button__2NDGD:hover {
	--light-color: var(--main-color-4);
	--shadow-color: var(--main-color-4);
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_button__2NDGD i {
	font-size: 2rem;
	padding-top: .25rem;
}

.styles_formControl__3DCLu .styles_file__3D7y2 .styles_filename__3-nUM:focus ~ .styles_button__2NDGD {
	box-shadow: none;
}

.styles_formControl__3DCLu .styles_group__3Y2nd {
	display: flex;
	flex-direction: row;
	grid-gap: .5rem;
	gap: .5rem;
	width: 100%;
}

.styles_formControl__3DCLu .styles_group__3Y2nd > * {
	min-width: 0;
	width: 100%;
}

.styles_formControl__3DCLu .styles_group__3Y2nd > * {
	border-radius: .25rem;
}

.styles_formControl__3DCLu .styles_group__3Y2nd > *:first-child {
	border-bottom-left-radius: 1rem;
	border-top-left-radius: 1rem;
}

.styles_formControl__3DCLu .styles_group__3Y2nd > *:last-child {
	border-bottom-right-radius: 1rem;
	border-top-right-radius: 1rem;
}

.styles_formControl__3DCLu .styles_group__3Y2nd button {
	margin: 0;
}

.styles_formControl__3DCLu strong {
	padding-left: 1em;

	font-size: 0.75em;
	font-weight: normal;

	color: rgba(var(--main-color-4), 1);

	opacity: 0;
	pointer-events: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.styles_formControl__3DCLu strong::after {
	content: '.';
    visibility: hidden;
}

.styles_formControl__3DCLu.styles_invalid__r0CLQ .styles_input__1FsOa,
.styles_formControl__3DCLu.styles_invalid__r0CLQ .styles_select__2w7Fd .styles_input__1FsOa,
.styles_formControl__3DCLu.styles_invalid__r0CLQ .styles_password__1WJ-l .styles_input__1FsOa,
.styles_formControl__3DCLu.styles_invalid__r0CLQ .styles_file__3D7y2 .styles_filename__3-nUM {
	--border-color: var(--main-color-4);
	--border-visibility: 0.75;
}

.styles_formControl__3DCLu.styles_invalid__r0CLQ label {
	color: rgba(var(--main-color-4), 1);
}

.styles_formControl__3DCLu.styles_invalid__r0CLQ strong {
	opacity: 1;
	pointer-events: all;
	-webkit-user-select: all;
	   -moz-user-select: all;
	    -ms-user-select: all;
	        user-select: all;
}

@media screen and (max-width: 768px) {
	.styles_formControl__3DCLu {
		min-width: unset;
		width: 100%;
		max-width: min(calc(100vw - 12rem), 350px);
	}
}

@media screen and (max-width: 425px) {
	.styles_formControl__3DCLu {
		width: 100%;
		max-width: max(calc(100vw - 12rem), 250px);
	}
}
.styles_main__36KS5 {
    min-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
        "form article";
}

.styles_login__2GNcK {
    border-radius: 0;
    grid-area: form;
    justify-self: center;
    align-self: center;
}

.styles_advertising__2FVnB {
    border-radius: 0;
    padding: 1.25rem 4rem;
    display: flex;
    align-items: center;

    background: -webkit-gradient(linear, right bottom, left top, from(rgb(var(--bg-color-0))), to(rgb(var(--bg-color-1)))), url(/static/media/advertising.9b56b616.jpg);

    background: linear-gradient(to left top, rgb(var(--bg-color-0)), rgb(var(--bg-color-1))), url(/static/media/advertising.9b56b616.jpg);
	background-size: cover;
	background-position: center;
	background-blend-mode: soft-light;
}

.styles_form__1Gq-u button ~ button {
    margin-left: 1rem;
}

.styles_form__1Gq-u a {
    margin: 0.5rem 0 1rem 1rem;
}

.styles_ul___1LaM li::before {
    content: '✓';
    font-size: 1em;
    color: rgb(var(--main-color-2));
    padding-right: 1rem;
}

@media only screen and (max-width: 768px) {
    .styles_main__36KS5 {
        grid-template-columns: minmax(22rem, 1fr);
    }

    .styles_login__2GNcK {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 22rem;
    }

    .styles_form__1Gq-u {
        display: contents;
    }

    .styles_form__1Gq-u fieldset {
        width: 100%;
    }

    .styles_form__1Gq-u button {
        margin: 1rem 0;
    }

    .styles_form__1Gq-u a {
        margin: 2em 0 4em 0;
    }

    .styles_main__36KS5 > .styles_advertising__2FVnB {
        display: none;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        pointer-events: none;
    }

    button.styles_btnSignin__3nAUS {
        min-width: 150px;
    }

    .styles_form__1Gq-u a {
        display: block;
    }
}

@media only screen and (min-width: 769px) {
    button.styles_btnSignup__1vIto {
        display: none;
    }
}
.styles_dialog__Hwxh4 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: contents;

	isolation: isolate;

	z-index: 999;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM {
	position: absolute;
	z-index: 9999;
	
	top: 50%;
	left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	        transform: translateY(-50%) translateX(-50%);

	min-width: 350px;
	max-width: 450px;
	margin: auto;
	padding: 2em;
	border-radius: 2em;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.styles_dialog__Hwxh4[open=""]::before {
	content: '';
	position: fixed;
	z-index: 9999;

	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background: rgba(15, 15, 15, .3);

	-webkit-backdrop-filter: blur(1em);

	        backdrop-filter: blur(1em);
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.styles_dialog__Hwxh4[open=""]::before{
		background: none;
		-webkit-backdrop-filter: blur(1em);
		        backdrop-filter: blur(1em);
	}
}

.styles_dialog__Hwxh4:not([open=""]) {
	display: none;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM button {
	margin-bottom: 0;
	align-self: flex-end;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM button ~ button {
	margin-left: 1rem;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM .styles_actions__20VfJ {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM .styles_form__1kBT6 {
	margin: 1.5em auto 0 auto;
	width: 100%;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM .styles_form__1kBT6 > div {
	width: 100%;
}

.styles_dialog__Hwxh4 .styles_card__2hQQM .styles_form__1kBT6 ~ .styles_actions__20VfJ button {
	margin-top: 0;
}
.styles_main__2pxIE {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_register__2tg5m {
    border-radius: 0;
    min-height: 100vh;

    display: grid;
    grid-template-rows: 1fr minmax(29rem, -webkit-min-content) 1fr;
    grid-template-rows: 1fr minmax(29rem, min-content) 1fr;
    grid-template-columns: minmax(22rem, 1fr);
}

.styles_register__2tg5m > h1 {
    margin-top: auto;
}

.styles_form__z9bcX {
    display: contents;
}

.styles_form__z9bcX fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.styles_form__z9bcX a {
    margin-top: 2em;
    text-align: center;
    width: 100%;
}

.styles_actions__3rFya {
    margin-inline: auto;

    display: flex;
    flex-direction: row-reverse;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    flex-wrap: wrap;

    justify-content: center;

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
}

@media screen and (max-width: 768px) {
    .styles_actions__3rFya {
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
    }
}
.styles_main__115Px {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styles_recovery__3QLEw {
    border-radius: 0;
    min-height: 100vh;

    display: grid;
    grid-template-rows: 1fr minmax(25rem, -webkit-min-content) 1fr;
    grid-template-rows: 1fr minmax(25rem, min-content) 1fr;
    grid-template-columns: minmax(22rem, 1fr);
}

.styles_recovery__3QLEw h1 {
    margin-top: auto;
}

.styles_form__1-NGy {
    display: contents;
}

.styles_form__1-NGy fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.styles_form__1-NGy a {
    margin-top: 2em;
    text-align: center;
    width: 100%;
}

.styles_actions__3_UcI {
    margin-inline: auto;

    display: flex;
    flex-direction: row;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    flex-wrap: wrap;

    justify-content: center;

    height: -webkit-fit-content;

    height: -moz-fit-content;

    height: fit-content;
}

@media screen and (max-width: 768px) {
    .styles_actions__3_UcI {
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
    }
}
:root {
    --hover-color: rgb(var(--main-color-2));
}

.styles_navbar__oqiUE * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.styles_navbar__oqiUE {
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 20rem;

    display: grid;
    grid-template-rows: auto 1fr auto;

    z-index: 999;

    -webkit-transition: width 300ms ease-in-out;

    transition: width 300ms ease-in-out;
}

.styles_navbar__oqiUE > * {
    min-width: 0;
}

.styles_navbar__oqiUE ~ main,
.styles_navbar__oqiUE ~ header {
    width: calc(100% - 20rem);
    float: right;
    padding: 0 2rem;
    padding-right: 12rem;
    -webkit-transition: width 300ms ease-in-out, padding 300ms ease-in-out;
    transition: width 300ms ease-in-out, padding 300ms ease-in-out;
}

.styles_navbar__oqiUE ~ header {
    height: 7.5rem;
    display: flex;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA {
    width: 6rem;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA ~ main,
.styles_navbar__oqiUE.styles_collapsed__2KHGA ~ header {
    width: calc(100% - 6rem);
    padding: 0 6rem;
    padding-right: 12rem;
}

.styles_navbar__oqiUE ~ header h1 {
    margin: auto 0;
}

.styles_navbarHeader__2j4fw {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7.5rem;
    padding: 0 1rem;

    -webkit-transition: 200ms ease-in-out;

    transition: 200ms ease-in-out;
}

.styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) .styles_navbarHeader__2j4fw {
    padding: 0 2.5rem 0 2.75rem;

}

.styles_navbar__oqiUE .styles_logo__28KVl {
    width: 100%;
    display: flex;
    align-items: center;
    -webkit-transition: all 300ms 100ms ease-in-out;
    transition: all 300ms 100ms ease-in-out;
}

.styles_navbar__oqiUE .styles_logo__28KVl > img {
    height: 2rem;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_logo__28KVl {
    opacity: 0;
    pointer-events: none;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    width: 0;
}

.styles_navbarHeader__2j4fw .styles_menuButton__30LIA {
    all: unset;
    background: none;
    border: none;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 2.5rem;
    cursor: pointer;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD {
    width: 2rem;
    height: .3rem;
    background: rgb(var(--text-color));
    border-radius: 1rem;
    -webkit-transition: all 50ms ease-in-out;
    transition: all 50ms ease-in-out;
    display: flex;
}

.styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD::before,
.styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: .3rem;
    background: rgb(var(--text-color));
    border-radius: 1rem;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD::before {
    height: .25rem;
    -webkit-transform: translateY(-.6rem);
            transform: translateY(-.6rem);
}

.styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD::after {
    height: .25rem;
    -webkit-transform: translateY(.6rem);
            transform: translateY(.6rem);
}

.styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) .styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD {
    background-color: transparent;
    pointer-events: none;
}

.styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) .styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) .styles_navbarHeader__2j4fw .styles_menuButton__30LIA .styles_menuButtonBar__W7StD::after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK {
    height: 100%;
    padding: 0 1rem;

    list-style: none;
    display: flex;
    flex-direction: column;
    
    grid-gap: .5rem;
    
    gap: .5rem;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li {
    position: relative;
    border-radius: 1rem;

    -webkit-transition: all 200ms ease-in-out;

    transition: all 200ms ease-in-out;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK > li:hover,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li.styles_active__HHa4i {
    background: rgb(var(--main-color-2));
    background: var(--hover-color);
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li i {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 2rem;
    padding: 1rem;
    color: rgb(var(--text-color));
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li i * {
    color: rgb(var(--text-color));
}


.styles_navbar__oqiUE .styles_navbarList__3RjVK li i,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li i * {
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
}

.styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) .styles_navbarList__3RjVK li i {
    padding: 1rem 1.75rem;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_expandLink__3VLcF {
    display: flex;
    align-items: center;
}

.styles_navbar__oqiUE.styles_navbar__oqiUE .styles_expandLink__3VLcF {
    cursor: default;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU li > a,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li > a {
    cursor: pointer;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_expandableItem__PeRx3 .styles_expandLink__3VLcF .styles_expandIcon__2dB47 {
    font-size: 1.5rem;
    margin-left: auto;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_expandableItem__PeRx3.styles_expanded__3jjFX .styles_expandLink__3VLcF .styles_expandIcon__2dB47 {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_itemName__3muoh {
    font-weight: 400;
    color: rgb(var(--text-color));
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK > li:hover,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li.styles_active__HHa4i {
    background: rgb(var(--main-color-2));
    background: var(--hover-color);
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK > li:hover *,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li.styles_active__HHa4i * {
    color: white;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;

    width: 100%;
    top: 50%;
    left: 0;

    border-radius: 1rem;

    z-index: 2;

    opacity: 0;
    pointer-events: none;
    -webkit-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;

    grid-gap: .5em;

    gap: .5em;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_tooltip__dJr2R {
    display: none;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_tooltip__dJr2R {
    padding: .5em;

    font-weight: 600;

    min-width: -webkit-max-content;

    min-width: -moz-max-content;

    min-width: max-content;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_tooltip__dJr2R span {
    padding: .5em;
    color: rgb(var(--text-color));
    font-weight: 600;
}


.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_expandableItem__PeRx3.styles_expanded__3jjFX .styles_navbarNestedList__3wNIU {
    opacity: 1;
    pointer-events: all;
    top: 90%;
    left: 1rem;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_expandableItem__PeRx3.styles_expanded__3jjFX .styles_navbarNestedList__3wNIU::before {
    content: '';
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200vw;
    height: 200vh;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%)
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU a {
    color: rgb(var(--text-color));
    font-weight: 600;
    white-space: nowrap;
    padding: 0.5em;
	border-radius: 0.75em;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU a span {
    font-weight: inherit;
    color: rgb(var(--text-color));;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU a:hover {
    background-color: rgb(var(--main-color-2));
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU a:hover * {
    color: white;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU,
.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li .styles_tooltip__dJr2R {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 100%;
    top: 0;
    border-radius: 1rem;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1rem;
    width: 100%;
    height: 100%;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li:hover .styles_navbarNestedList__3wNIU,
.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li .styles_expandableItem__PeRx3.styles_expanded__3jjFX .styles_navbarNestedList__3wNIU,
.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li:hover .styles_tooltip__dJr2R {
    top: 10%;
    left: calc(100% + 1rem);
    pointer-events: auto;
    opacity: 1;
}

.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU .styles_itemName__3muoh {
    display: none;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li .styles_navbarNestedList__3wNIU .styles_itemName__3muoh,
.styles_navbar__oqiUE .styles_navbarList__3RjVK li .styles_expandableItem__PeRx3.styles_expanded__3jjFX .styles_navbarNestedList__3wNIU .styles_itemName__3muoh {
    opacity: 1;
    display: block;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li a .styles_itemName__3muoh,
.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_navbarList__3RjVK li a .styles_expandIcon__2dB47 {
    opacity: 0;
    pointer-events: none;
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.styles_profile__kg0Gh .styles_profilePic__1DBpp {
    position: relative;
    display: block;

    width: 4rem;
    height: 4rem;

    overflow: hidden;
    color: transparent;

    margin: 1rem .75rem 1rem 1.75rem;

    border-radius: 1rem;

    background-color: rgb(var(--main-color-0));
    
    -webkit-transition: margin 200ms ease-in-out;
    
    transition: margin 200ms ease-in-out;
}

.styles_profile__kg0Gh .styles_profilePic__1DBpp.styles_notFound__287e9 {
    outline: solid 2rem rgb(var(--main-color-0));
    outline-offset: -2rem;
}

.styles_profile__kg0Gh .styles_profilePic__1DBpp.styles_notFound__287e9::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 75%;
    top: 60%;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(1.25);
            transform: translateX(-50%) scale(1.25);
    border: solid .25rem rgb(var(--main-color-0));
    border-radius: 45%;
    background-color: white;
}

.styles_profile__kg0Gh .styles_profilePic__1DBpp.styles_notFound__287e9::after {
    content: '';
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-70%);
            transform: translateX(-50%) translateY(-70%);
    border: solid .25rem rgb(var(--main-color-0));
    border-radius: 100%;
    background-color: white;
}

.styles_profile__kg0Gh .styles_profileName__17nak {
    font-size: 1rem;
    margin-right: auto;

    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    white-space: nowrap;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C {
    position: relative;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenuButton__2nSWV {
    font-size: 1.5rem;

    padding: .5rem;
    margin-right: 2.25rem;

    display: flex;

    cursor: pointer;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenuToggle__32Eag {
    display: none;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C .styles_profileMenuOptions__2048x {
    position: absolute;
    bottom: 50%;
    left: 0;

    display: flex;
    flex-direction: column;

    padding: 0.5rem;
    border-radius: 1rem;

    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;

    grid-gap: .5rem;

    gap: .5rem;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C .styles_profileMenuToggle__32Eag {
    position: relative;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C .styles_profileMenuToggle__32Eag:checked ~ .styles_profileMenuOptions__2048x {
    left: 50%;
    bottom: 0;
    opacity: 1;
    pointer-events: all;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C .styles_profileMenuToggle__32Eag:checked ~ .styles_profileMenuButton__2nSWV::before {
    content: '';
    position: fixed;
    width: 200vw;
    height: 200vh;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    cursor: default;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C .styles_profileMenuOptions__2048x li label {
    display: block;

    padding: 0.5em;
	border-radius: 0.75em;

    text-decoration: none;
    font-weight: 600;
    color: rgb(var(--text-color));

    white-space: nowrap;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;

    cursor: pointer;
}

.styles_navbar__oqiUE .styles_profile__kg0Gh .styles_profileMenu__1V68C .styles_profileMenuOptions__2048x li label:hover {
    color: white;
    background: rgb(var(--main-color-2));
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_profileMenu__1V68C {
    visibility: hidden;
}

.styles_navbar__oqiUE.styles_collapsed__2KHGA .styles_profile__kg0Gh .styles_profilePic__1DBpp {
    margin: 1rem;
}

iframe[id="omnizeButtonFrame"],
iframe[id="omnizeWgtFrame"] {
	pointer-events: all;
}

@media screen and (max-width: 1024px) {
    .styles_navbar__oqiUE ~ main,
    .styles_navbar__oqiUE ~ header {
        padding: 0 1rem;
        padding-right: 5rem;
    }
    
    .styles_navbar__oqiUE.styles_collapsed__2KHGA ~ main,
    .styles_navbar__oqiUE.styles_collapsed__2KHGA ~ header {
        padding: 0 2rem;
        padding-right: 5rem;
    }
}


@media screen and (max-width: 768px) {
    .styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) {
        position: fixed;
        background: -webkit-gradient(linear, left top, left bottom, from(rgb(var(--bg-color-0))), to(rgb(var(--bg-color-1))));
        background: linear-gradient(to bottom, rgb(var(--bg-color-0)), rgb(var(--bg-color-1)));
        outline: solid rgba(var(--bg-color-1), 1) thin;
        box-shadow: 
            0 25px 25px -15px rgba(0, 10, 20, 0.1);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .styles_navbar__oqiUE:not(.styles_collapsed__2KHGA) {
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(var(--bg-color-0), 0.25)), to(rgba(var(--bg-color-1), 0.5)));
            background: linear-gradient(to bottom, rgba(var(--bg-color-0), 0.25), rgba(var(--bg-color-1), 0.5));
            -webkit-backdrop-filter: blur(.5em);
                    backdrop-filter: blur(.5em);
        }
    }

    .styles_navbar__oqiUE ~ main,
    .styles_navbar__oqiUE ~ header {
        width: calc(100% - 6rem);
        padding: 0 1rem;
    }
    
    .styles_navbar__oqiUE.styles_collapsed__2KHGA ~ main,
    .styles_navbar__oqiUE.styles_collapsed__2KHGA ~ header {
        width: calc(100% - 6rem);
        padding: 0 1rem;
    }
}
.styles_header__D7Cd9 ~ main {
    min-height: calc(100vh - 7.5rem);
}

.styles_header__D7Cd9 h1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    overflow-wrap: normal;
}

.styles_header__D7Cd9 aside {
    margin: auto;
    margin-right: 0;

    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
    gap: 2rem;

    align-items: center;
}

.styles_header__D7Cd9 aside p {
    font-size: 2rem;
    margin: 0;
}

.styles_header__D7Cd9 aside button {
    all: unset;

    font-size: 1.5rem;

    box-sizing: border-box;
    display: flex;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .styles_header__D7Cd9 strong,
    .styles_header__D7Cd9 p {
        display: none;
    }

    .styles_header__D7Cd9 aside button {
        padding: 1rem;
    }
}
.styles_main__3D7pS {
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 1.5rem !important;
}

.styles_callDisposition__3DCx4, 
.styles_callsPerHour__3m94-,
.styles_callsPerType__2Bpeh,
.styles_callsPerQueue__20Idn {
    padding: 2rem;
    border-radius: 1rem;
    grid-column: span 12;
}


/* CallDisposition */

.styles_callDisposition__3DCx4 h2 {
    font-size: 2.5rem;
    font-weight: 300;
}


.styles_callDisposition__3DCx4 > div,
.styles_callDisposition__3DCx4 > div > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    grid-auto-flow: row;
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
}

.styles_callDisposition__3DCx4 section {
    grid-column: span 1;
}

.styles_callDisposition__3DCx4 > div > div:first-of-type section:first-of-type {
    --marker-color: var(--main-color-2);
}

.styles_callDisposition__3DCx4 > div > div:first-of-type section:last-of-type {
    --marker-color: var(--main-color-4);
}

.styles_callDisposition__3DCx4 > div > div:last-of-type section:first-of-type {
    --marker-color: var(--main-color-3);
}

.styles_callDisposition__3DCx4 > div > div:last-of-type section:last-of-type {
    --marker-color: var(--main-color-0);
}

.styles_callDisposition__3DCx4 section h3 {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_callDisposition__3DCx4 section h3::before {
    content: '';
    width: 1em;
    height: 1em;

    display: inline-block;
    
    border-radius: 1rem;
    border: solid .2em rgb(var(--marker-color));
}

.styles_callDisposition__3DCx4 section p {
    font-size: 3rem;

    margin: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;

    font-weight: 500;
}

.styles_callDisposition__3DCx4 section p small {
    font-size: .5em;
    font-weight: normal;
}


/* CallsPerHour */

.styles_callsPerHour__3m94- {
    padding-left: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.styles_callsPerHour__3m94- .styles_chart__2TEBp {
    min-height: 16rem;
    padding-right: 1rem;
    display: grid;
}

.styles_callsPerHour__3m94- .styles_chart__2TEBp * {
    min-width: 0;
}

.styles_callsPerHour__3m94- > h2 {
    font-size: 2.5rem;
    padding: 0 2rem;
}

.styles_callsPerHour__3m94- .styles_customTooltip__1UnDO {
    padding: 1rem;

    border-radius: 1rem;
    box-shadow: none;
}

.styles_callsPerHour__3m94- .styles_customTooltip__1UnDO h3 {
    margin-bottom: 1rem;
}

.styles_callsPerHour__3m94- .styles_customTooltip__1UnDO time {
    margin-bottom: 1rem;
    text-align: end;
    display: inline-block;
    width: 100%;
    margin-bottom: .25rem;
}


/* CallsPerType */

.styles_callsPerType__2Bpeh {
    grid-column: span 7;
    display: grid;
    grid-template-rows: 4rem 12rem;
    grid-template-columns: auto 20rem;
}

.styles_callsPerType__2Bpeh h2 {
    grid-column: span 1;
    grid-row: span 1;
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT {
    grid-column: span 1;
    grid-row: 2 / span 1;

    display: flex;
    align-items: center;
}

.styles_callsPerType__2Bpeh .styles_chart__2TEBp {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    min-height: 16rem;
    min-width: 16rem;
    display: grid;
    max-width: 200px;
    justify-self: end;
}

.styles_callsPerType__2Bpeh .styles_chart__2TEBp svg {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.styles_callsPerType__2Bpeh .styles_chart__2TEBp * {
    min-width: 0;
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li {
    position: relative;
    margin-block: .25rem;

    cursor: pointer;
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    height: .5rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: rgb(var(--marker-color));
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li.styles_active__1gelj::before {
    outline: solid .15rem rgba(var(--marker-color), .5);
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li:first-child {
    --marker-color: var(--main-color-1);
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li:nth-child(2) {
    --marker-color: var(--main-color-2);
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li:nth-child(3) {
    --marker-color: var(--main-color-3);
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li:last-child {
    --marker-color: var(--main-color-4);
}

.styles_callsPerType__2Bpeh .styles_legend__32JMT ul li span {
    color: rgb(var(--text-color));
    margin-left: 2.5rem;
}

.styles_callsPerType__2Bpeh .styles_customTooltip__1UnDO {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: none;
}

.styles_callsPerType__2Bpeh .styles_customTooltip__1UnDO h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}


/* CallsPerQueue */

.styles_callsPerQueue__20Idn {
    grid-column: span 7;
    display: grid;
    grid-template-rows: 4rem 12rem;
    grid-template-columns: auto 20rem;
}

.styles_callsPerQueue__20Idn h2 {
    grid-column: span 1;
    grid-row: span 1;
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT {
    grid-column: span 1;
    grid-row: 2 / span 1;

    display: flex;
    align-items: center;
}

.styles_callsPerQueue__20Idn .styles_chart__2TEBp {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    min-height: 16rem;
    min-width: 16rem;
    display: grid;
    max-width: 200px;
    justify-self: end;
}

.styles_callsPerQueue__20Idn .styles_chart__2TEBp svg {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.styles_callsPerQueue__20Idn .styles_chart__2TEBp * {
    min-width: 0;
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li {
    position: relative;
    margin-block: .25rem;

    cursor: pointer;
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 2rem;
    height: .5rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: rgb(var(--marker-color));
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li.styles_active__1gelj::before {
    outline: solid .15rem rgba(var(--marker-color), .5);
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li:first-child {
    --marker-color: var(--main-color-1);
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li:nth-child(2) {
    --marker-color: var(--main-color-2);
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li:nth-child(3) {
    --marker-color: var(--main-color-3);
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li:nth-child(4) {
    --marker-color: var(--main-color-4);
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li:last-child {
    --marker-color: var(--main-color-5);
}

.styles_callsPerQueue__20Idn .styles_legend__32JMT ul li span {
    color: rgb(var(--text-color));
    margin-left: 2.5rem;
}

.styles_callsPerQueue__20Idn .styles_customTooltip__1UnDO {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: none;
}

.styles_callsPerQueue__20Idn .styles_customTooltip__1UnDO h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}


/* Balance */

.styles_balance__1n_cw {
    grid-column: 8 / span 5;
    grid-row:  3 / span 2;

    display: grid;
    grid-template-rows: repeat(4, 10rem);
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
}

.styles_balance__1n_cw * {
    min-width: 0;
}

.styles_balance__1n_cw section {
    --status-color: var(--main-color-2);

    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: -webkit-max-content auto;
    grid-template-columns: max-content auto;

    padding: 2rem;
    border-radius: 1rem;

    background: 
        -webkit-gradient(linear, left top, left bottom, from(rgba(var(--bg-color-0), .25)), to(rgba(var(--bg-color-1), .5))),
        rgb(var(--status-color));

    background: 
        linear-gradient(to bottom, rgba(var(--bg-color-0), .25), rgba(var(--bg-color-1), .5)),
        rgb(var(--status-color));
    background-blend-mode: luminosity;
    outline-color: rgba(var(--status-color), .5);
}

.styles_balance__1n_cw section[data-value="0"] {
    --status-color: var(--main-color-3);
}

.styles_balance__1n_cw section h3 {
    grid-row: 1;
    grid-column: span 1;
}

.styles_balance__1n_cw section p {
    grid-row: 2;
    grid-column: span 1;

    margin: 0;

    font-size: 2.5rem;
}

.styles_balance__1n_cw section * {
    color: white;
}

.styles_balance__1n_cw section i {
    grid-column: 2;
    grid-row: span 2;

    display: flex;
    justify-content: stretch;
    align-items: stretch;

    padding-block: .5rem;
}

.styles_balance__1n_cw section i svg {
    margin-left: auto;
    height: 100%;
    width: auto;
}

.styles_balance__1n_cw table {
    display: contents;
}

.styles_balance__1n_cw tbody {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: minmax(-webkit-min-content, auto) auto;
    grid-template-columns: minmax(min-content, auto) auto;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 2rem;
}

.styles_balance__1n_cw tbody[class="glass"] {
    border-radius: 1rem;
}

.styles_balance__1n_cw tr {
    display: contents;
}

.styles_balance__1n_cw tbody tr th,
.styles_balance__1n_cw tbody tr td {
    font-size: 1.5rem;
}

.styles_balance__1n_cw tbody tr td {
    text-align: end;
}

.styles_balance__1n_cw tbody tr th {
    text-align: start;
    font-weight: normal;
}

.styles_balance__1n_cw tbody tr th {
    overflow-wrap: break-word;
}

@media screen and (max-width: 768px) {
    .styles_callDisposition__3DCx4,
    .styles_callsPerHour__3m94-,
    .styles_callsPerType__2Bpeh,
    .styles_callsPerQueue__20Idn,
    .styles_balance__1n_cw {
        grid-column: span 12;
        grid-row: span 1;
    }
}

@media screen and (max-width: 1024px) {
    .styles_callsPerType__2Bpeh,
    .styles_callsPerQueue__20Idn {
        display: grid;
        grid-template-rows: 4rem 16rem auto;
        grid-template-columns: minmax(16rem, 1fr);
        grid-template-areas: 
            "title"
            "chart"
            "legend";
    }

    .styles_callsPerType__2Bpeh h2,
    .styles_callsPerQueue__20Idn h2 {
        grid-area: title;
    }

    .styles_callsPerType__2Bpeh .styles_chart__2TEBp,
    .styles_callsPerQueue__20Idn .styles_chart__2TEBp {
        grid-area: chart;
        margin: auto;
    }

    .styles_callsPerType__2Bpeh .styles_chart__2TEBp svg,
    .styles_callsPerQueue__20Idn .styles_chart__2TEBp svg {
        -webkit-transform: none;
                transform: none;
    }
    
    .styles_callsPerType__2Bpeh .styles_legend__32JMT,
    .styles_callsPerQueue__20Idn .styles_legend__32JMT {
        grid-area: legend;
    }

    .styles_callsPerType__2Bpeh .styles_legend__32JMT ul,
    .styles_callsPerQueue__20Idn .styles_legend__32JMT ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
    }

    .styles_callsPerType__2Bpeh .styles_legend__32JMT ul li span,
    .styles_callsPerQueue__20Idn .styles_legend__32JMT ul li span {
        margin: 0 1rem;
    }

    .styles_callsPerType__2Bpeh .styles_legend__32JMT ul li::before,
    .styles_callsPerQueue__20Idn .styles_legend__32JMT ul li::before {
        width: .5rem;
    }
    
    .styles_balance__1n_cw tbody {
        padding: 0 1rem;
        grid-template-columns: repeat(2, 1fr);
    }

    .styles_balance__1n_cw tbody[class="glass"] {
        background: none;
        outline: none;
        box-shadow: none;
    }

    .styles_balance__1n_cw section i {
        display: none;
    }
}
.styles_table__3dAiQ {
    width: 100%;
    border: 0;
    border-collapse: collapse;
    table-layout: auto;
}

.styles_table__3dAiQ tbody tr:nth-child(2n) {
    background: -webkit-gradient(linear, right top, left top, from(rgb(var(--bg-color-0))), to(transparent));
    background: linear-gradient(to left, rgb(var(--bg-color-0)), transparent);
}

.styles_table__3dAiQ tbody tr:nth-child(2n+1) {
    background: -webkit-gradient(linear, left top, right top, from(rgb(var(--bg-color-1))), to(transparent));
    background: linear-gradient(to right, rgb(var(--bg-color-1)), transparent);
}

.styles_table__3dAiQ td,
.styles_table__3dAiQ th {
    padding: 1rem;
}

.styles_table__3dAiQ thead tr > *:first-child,
.styles_table__3dAiQ tbody tr > *:first-child,
.styles_table__3dAiQ tfoot tr > *:first-child,
.styles_table__3dAiQ tfoot tr > *:first-child {
    padding-left: 2rem;
}

.styles_table__3dAiQ thead tr > *:last-child,
.styles_table__3dAiQ tbody tr > *:last-child,
.styles_table__3dAiQ tfoot tr > *:last-child,
.styles_table__3dAiQ tfoot tr > *:last-child {
    padding-right: 2rem;
}

.styles_table__3dAiQ tbody tr > *:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.styles_table__3dAiQ tbody tr > *:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.styles_table__3dAiQ tfoot tr > * {
    font-weight: bold;
}

.styles_table__3dAiQ button {
    -webkit-animation: none 0s ease 0s 1 normal none running;
            animation: none 0s ease 0s 1 normal none running;
    -webkit-backface-visibility: visible;
            backface-visibility: visible;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    border: medium none currentColor;
    border-collapse: separate;
    -webkit-border-image: none;
            border-image: none;
    border-radius: 0;
    border-spacing: 0;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: #000;
    -webkit-columns: auto;
            columns: auto;
    -webkit-column-count: auto;
            column-count: auto;
    -webkit-column-fill: balance;
            column-fill: balance;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
    -webkit-column-rule: medium none currentColor;
            column-rule: medium none currentColor;
    -webkit-column-span: 1;
            column-span: 1;
    -webkit-column-width: auto;
            column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    empty-cells: show;
    float: none;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    height: auto;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    left: auto;
    letter-spacing: normal;
    list-style: disc outside none;
    margin: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 2;
    outline: medium none invert;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    -webkit-perspective: none;
            perspective: none;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    position: static;
    right: auto;
    -moz-tab-size: 8;
         tab-size: 8;
    table-layout: auto;
    text-align: left;
    text-align-last: auto;
    text-decoration: none;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    -webkit-transform: none;
            transform: none;
    -webkit-transform-origin: 50% 50% 0;
            transform-origin: 50% 50% 0;
    -webkit-transform-style: flat;
            transform-style: flat;
    -webkit-transition: none 0s ease 0s;
    transition: none 0s ease 0s;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 2;
    width: auto;
    word-spacing: normal;
    z-index: auto;
    all: initial;

    font-size: 2rem;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
}

.styles_table__3dAiQ button svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_table__3dAiQ button:hover svg {
    fill: rgb(var(--main-color-1));
}
.styles_pagination__3q_o_ {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_pagination__3q_o_ label {
    margin-bottom: .25rem;
}

.styles_pagination__3q_o_ .styles_buttons__8ovO4 {
    display: inline-flex;
    grid-gap: .25rem;
    gap: .25rem;
}

.styles_buttons__8ovO4 button {
    all: unset;
    display: inline-flex;
    font-size: 2rem;
    cursor: pointer;
}

.styles_buttons__8ovO4 button:disabled {
    opacity: .5;
    cursor: default;
}
.styles_list__2mRkF {
    overflow-x: visible;
}

.styles_actions__3vXHU button svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_actions__3vXHU button:hover svg,
.styles_actions__3vXHU button:active svg {
    fill: rgb(var(--main-color-1));
}

.styles_listActions__2W7_- {
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    grid-gap: 1rem;

    gap: 1rem;
    margin-block: 1rem;
}

.styles_listActions__2W7_- button {
    margin: 0;
}

.styles_listItemMenu__1L60t {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.styles_listItemMenu__1L60t input {
    display: none;
}

.styles_listItemMenu__1L60t label {
    font-size: 2rem;
    display: contents;
    cursor: pointer;
}

.styles_listItemMenu__1L60t label svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_listItemMenu__1L60t label:hover svg {
    fill: rgb(var(--main-color-1));
}

.styles_listItemMenu__1L60t input:checked + label::before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    cursor: default;
}

.styles_listItemMenu__1L60t ul {    
    position: absolute;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    right: 75%;
    top: 0;

    -webkit-transform: translateY(-1.65rem);

            transform: translateY(-1.65rem);

    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    gap: .5rem;

    margin: 0;
    padding: .5rem;
    border-radius: 1rem;

    opacity: 0;
    pointer-events: none;

    -webkit-transition: 200ms ease;

    transition: 200ms ease;
}

.styles_listItemMenu__1L60t input:checked ~ ul {
    top: 50%;
    right: 100%;

    opacity: 1;
    pointer-events: all;
}

.styles_listItemMenu__1L60t ul li {
    display: grid;
    justify-content: stretch;
}

.styles_listItemMenu__1L60t ul li a {
    font-weight: 500;
    margin: 0;
    padding: .5em;
    border-radius: .75em;

    text-decoration: none;
    color: rgb(var(--text-color));

    -webkit-transition: 300ms ease-in-out;

    transition: 300ms ease-in-out;
}

.styles_listItemMenu__1L60t ul li a:hover {
    background-color: rgb(var(--main-color-1));
    color: white;
}

.styles_listItemMenu__1L60t input:disabled + label {
    pointer-events: none;
}

.styles_listItemMenu__1L60t input:disabled + label > * {
    opacity: .5;
}

.styles_form__2-_E8 {
    overflow: visible;
}

.styles_form__2-_E8 form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_form__2-_E8 form fieldset {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 0 1rem;
    gap: 0 1rem;
}

.styles_form__2-_E8 form fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.styles_form__2-_E8 .styles_fieldsetToggle__rZIzs {
    display: none;
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw {
    display: contents;
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend {
    position: relative;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend span {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    color: rgb(var(--text-color));
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend span::after {
    position: relative;
    content: '';

    width: -webkit-fill-available;

    width: -moz-available;

    width: stretch;
    height: .15rem;

    top: 0;
    left: 0;

    border-radius: 1rem;
    background-color: rgb(var(--text-color));
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend i {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    -webkit-transition: 200ms ease-in-out;

    transition: 200ms ease-in-out;
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend span,
.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend span::after,
.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend i svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend:hover span {
    color: rgb(var(--main-color-1));
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend:hover span::after {
    background-color: rgb(var(--main-color-1));
}

.styles_form__2-_E8 .styles_fieldsetButton__3SgDw legend:hover i svg {
    fill: rgb(var(--main-color-1));
}

.styles_form__2-_E8 .styles_fieldsetContent__2cpqF {
    display: contents;
}

.styles_form__2-_E8 .styles_fieldsetContent__2cpqF > div {
    max-height: 0;
    opacity: 0;

    pointer-events: none;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
}

.styles_form__2-_E8 .styles_fieldsetToggle__rZIzs:checked ~ .styles_fieldsetContent__2cpqF > * {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all;
}

.styles_form__2-_E8 .styles_fieldsetToggle__rZIzs:checked ~ .styles_fieldsetButton__3SgDw legend i {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}

.styles_form__2-_E8 .styles_formActions__1kg35 {
    padding-inline: 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    grid-gap: 1rem;

    gap: 1rem;
    margin-block: 1rem;
    margin-left: auto;
}

.styles_form__2-_E8 .styles_formActions__1kg35 button {
    margin: 0;
}

.styles_nodata__3JFqA {
    margin: 0;
    padding: 0 2rem;

    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}
.styles_list__SqOl_ li {
    padding: .5rem 0;
    position: relative;
}

.styles_item__16mh4 {
    position: relative;

    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    
    padding: 0 1rem;
    border-radius: 1rem;
}

.styles_item__16mh4 > i {
    display: contents;
}

.styles_item__16mh4 > i > svg {
    margin-left: auto;
    font-size: 2rem;
    fill: rgba(var(--text-color), .3);
    margin-block: .65rem;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
}

.styles_content__1_Ivd {
    display: contents;
    position: relative;
}

.styles_content__1_Ivd * {
    margin: 0;
    grid-row: 1;
}

/* ordered */
ol .styles_item__16mh4::after {
    content: attr(id);
    color: rgba(var(--text-color), .5);
    font-weight: 500;
    z-index: 1;
    order: -1;
}
.styles_form__2kBXi {
    overflow: visible;
}

.styles_form__2kBXi form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_form__2kBXi form fieldset {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 0 1rem;
    gap: 0 1rem;
}

.styles_form__2kBXi form fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.styles_form__2kBXi .styles_fieldsetToggle__2QncN {
    display: none;
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 {
    display: contents;
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend {
    position: relative;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend span {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    color: rgb(var(--text-color));
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend span::after {
    position: relative;
    content: '';

    width: -webkit-fill-available;

    width: -moz-available;

    width: stretch;
    height: .15rem;

    top: 0;
    left: 0;

    border-radius: 1rem;
    background-color: rgb(var(--text-color));
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend i {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    -webkit-transition: 200ms ease-in-out;

    transition: 200ms ease-in-out;
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend span,
.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend span::after,
.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend i svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend:hover span {
    color: rgb(var(--main-color-1));
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend:hover span::after {
    background-color: rgb(var(--main-color-1));
}

.styles_form__2kBXi .styles_fieldsetButton__1JpH5 legend:hover i svg {
    fill: rgb(var(--main-color-1));
}

.styles_form__2kBXi .styles_fieldsetContent__7G8Ex {
    display: contents;
}

.styles_form__2kBXi .styles_fieldsetContent__7G8Ex > div {
    max-height: 0;
    opacity: 0;

    pointer-events: none;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
}

.styles_form__2kBXi .styles_fieldsetToggle__2QncN:checked ~ .styles_fieldsetContent__7G8Ex > * {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all;
}

.styles_form__2kBXi .styles_fieldsetToggle__2QncN:checked ~ .styles_fieldsetButton__1JpH5 legend i {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}

.styles_form__2kBXi .styles_formActions__19Bnf {
    padding-inline: 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    grid-gap: 1rem;

    gap: 1rem;
    margin-block: 1rem;
    margin-left: auto;
}

.styles_form__2kBXi .styles_formActions__19Bnf button {
    margin: 0;
}

.styles_form__2kBXi fieldset:last-of-type > div {
    grid-column: 1;
}

.styles_nodata__1FlqZ {
    margin: 0;
    padding: 0 2rem;

    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}
.styles_main__aka7N {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.styles_search__2t93T {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.styles_search__2t93T label {
    font-size: 1.5rem;
}

.styles_search__2t93T input {
    border-radius: 5rem;
    flex-grow: .25;
    max-width: 24rem;
}

.styles_list__3uxfx {
    all: unset;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(calc(100vw - 20rem) / 3), auto));
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.styles_pabx__-S8tD {
    padding: 2rem;
    padding-top: 1.5rem;
    border-radius: 1rem;

    cursor: pointer;
}

.styles_pabx__-S8tD h3 {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-weight: 500;
}

.styles_pabx__-S8tD h3::after {
    content: ': : :';
    color: rgba(var(--text-color), .1);
    font-size: 2rem;
}

.styles_pabx__-S8tD table {
    width: 100%;

    display: grid;
    grid-gap: 1rem;
    gap: 1rem;

    font-size: 1.25rem;
}

.styles_pabx__-S8tD tbody {
    width: 100%;

    display: grid;
    grid-template-columns: auto minmax(-webkit-min-content, auto);
    grid-template-columns: auto minmax(min-content, auto);
    grid-gap: .25rem .5rem;
    gap: .25rem .5rem;
}

.styles_pabx__-S8tD tbody.styles_mainData__33V2W {
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
}

.styles_pabx__-S8tD tr {
    display: contents;
}

.styles_pabx__-S8tD th {
    text-align: start;
    font-weight: normal;
}

.styles_pabx__-S8tD td {
    text-align: end;
    font-weight: 500;
}

.styles_nodata__2FtfM {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 100;
}

@media screen and (max-width: 1024px) {
    .styles_search__2t93T input {
        max-width: 100%;
        flex-grow: .5;
    }

    .styles_list__3uxfx {
        grid-template-columns: repeat(auto-fit, minmax(calc(calc(100vw - 20rem) / 2), auto));
    }

    .styles_pabx__-S8tD table {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .styles_list__3uxfx {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 425px) {
    .styles_search__2t93T label {
        display: none;
    }

    .styles_search__2t93T input {
        width: calc(100vw - 8rem);
        margin: auto;
    }

    .styles_pabx__-S8tD table td {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1600px) {
    .styles_list__3uxfx {
        grid-template-columns: repeat(auto-fill, minmax(28rem, auto));
    }
}
.styles_main__3jgYi {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    padding-bottom: 1.5rem !important;
}

.styles_nodata__3nLMw {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 100;
}
/* branches */

.styles_component__k9WgY {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: visible;
}

.styles_component__k9WgY header {
    padding: 3rem 2rem;
    border-radius: 1rem;

    display: grid;
    grid-template-columns: auto -webkit-max-content;
    grid-template-columns: auto max-content;
    grid-gap: 4rem;
    gap: 4rem;
}

.styles_component__k9WgY.styles_pabx__2Rk_P header {
    padding: 2rem;

    grid-template-columns: 1fr .5fr -webkit-max-content;

    grid-template-columns: 1fr .5fr max-content;
}

.styles_info__1-E-U {
    display: flex;
    align-items: center;
}

.styles_pabx__2Rk_P .styles_info__1-E-U {
    display: block;
}

.styles_info__1-E-U > h2 {
    margin: 0;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_info__1-E-U > h2 i {
    display: contents;
}

.styles_info__1-E-U > h2 i svg {
    font-size: inherit;
}

.styles_component__k9WgY.styles_pabx__2Rk_P header h2,
.styles_component__k9WgY.styles_pabx__2Rk_P header h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.styles_pabx__2Rk_P .styles_info__1-E-U h2 {
    display: block;
    font-weight: 500;
}

.styles_branches__33qIj > h3 {
    display: flex;
    align-items: center;
    font-weight: normal;
    grid-gap: .5rem;
    gap: .5rem;
}

.styles_branches__33qIj > h3 i {
    font-size: 1.5rem;
    margin-top: auto;
}

.styles_info__1-E-U table {
    display: grid;
    grid-template-columns: minmax(-webkit-max-content, auto) -webkit-max-content;
    grid-template-columns: minmax(max-content, auto) max-content;
    grid-gap: 1rem .25rem;
    gap: 1rem .25rem;
}

.styles_branches__33qIj table {
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content;
    grid-gap: 1rem 2rem;
    gap: 1rem 2rem;
}

.styles_branches__33qIj .styles_form__3XKoO {
    background-color: green;
}

.styles_component__k9WgY header thead,
.styles_component__k9WgY header tbody,
.styles_component__k9WgY header tr {
    display: contents;
}

.styles_component__k9WgY header th {
    text-align: start;
    font-weight: normal;
    font-size: 1.25rem;
}

.styles_component__k9WgY header td {
    text-align: start;
    font-size: 1.75rem;
}

.styles_actions__3_CKK {
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;

    display: flex;
    justify-content: space-between;
    grid-gap: .5rem;
    gap: .5rem;

    margin-left: auto;
}

.styles_actions__3_CKK button {
    all: unset;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;

    font-size: 2rem;
    padding: .5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transition: 200ms ease;

    transition: 200ms ease;

    cursor: pointer;
}

.styles_component__k9WgY:not(.styles_open__1DmWo) .styles_actions__3_CKK .styles_toggle__1JwKi {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}

.styles_actions__3_CKK .styles_refresh__2mnpI:disabled {
    cursor: default;
    pointer-events: none;
    opacity: .5;
}

.styles_pabx__2Rk_P .styles_actions__3_CKK {
    flex-direction: column;
}

.styles_component__k9WgY > section {
    padding-bottom: 1rem;
}

.styles_component__k9WgY .styles_form__3XKoO {
    overflow: visible;
}

.styles_component__k9WgY .styles_form__3XKoO form {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_component__k9WgY .styles_form__3XKoO form fieldset {
    padding-inline: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 0 1rem;
    gap: 0 1rem;
}

.styles_component__k9WgY .styles_form__3XKoO form fieldset legend {
    padding-inline: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetToggle__4I70i {
    display: none;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS {
    display: contents;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend {
    position: relative;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend span {
    position: relative;
    width: 100%;
    display: inline-flex;
    align-items: center;
    grid-gap: .5rem;
    gap: .5rem;
    color: rgb(var(--text-color));
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend span::after {
    position: relative;
    content: '';

    width: -webkit-fill-available;

    width: -moz-available;

    width: stretch;
    height: .15rem;

    top: 0;
    left: 0;

    border-radius: 1rem;
    background-color: rgb(var(--text-color));
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend i {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    -webkit-transition: 200ms ease-in-out;

    transition: 200ms ease-in-out;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend span,
.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend span::after,
.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend i svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend:hover span {
    color: rgb(var(--main-color-1));
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend:hover span::after {
    background-color: rgb(var(--main-color-1));
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend:hover i svg {
    fill: rgb(var(--main-color-1));
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetContent__1Cmig {
    display: contents;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetContent__1Cmig > div {
    max-height: 0;
    opacity: 0;

    pointer-events: none;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetToggle__4I70i:checked ~ .styles_fieldsetContent__1Cmig > * {
    max-height: 200vh;
    opacity: 1;
    pointer-events: all;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetToggle__4I70i:not(:checked) ~ .styles_fieldsetButton__1AWMS legend i {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
}

.styles_component__k9WgY .styles_form__3XKoO .styles_formActions__2v-qo {
    padding-inline: 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    grid-gap: 1rem;

    gap: 1rem;
    margin-block: 1rem;
    margin-left: auto;
}

.styles_component__k9WgY .styles_form__3XKoO .styles_formActions__2v-qo button {
    margin: 0;
}

.styles_component__k9WgY .styles_list__1i5vc {
    overflow-x: auto;
}

.styles_actions__3_CKK button svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_actions__3_CKK button:hover svg,
.styles_actions__3_CKK button:active svg {
    fill: rgb(var(--main-color-1));
}

.styles_listActions__2-j0B {
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    grid-gap: 1rem;

    gap: 1rem;
    margin-block: 1rem;
}

.styles_listActions__2-j0B > *:nth-last-child(2) {
    flex-grow: 1;
}

.styles_listActions__2-j0B button {
    margin: 0;
}

.styles_listActions__2-j0B button:only-child {
    margin-left: auto;
}

.styles_listItemMenu__2O2vA {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.styles_listItemMenu__2O2vA input {
    display: none;
}

.styles_listItemMenu__2O2vA label {
    font-size: 2rem;
    display: contents;
    cursor: pointer;
}

.styles_listItemMenu__2O2vA label svg {
    -webkit-transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
    transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.styles_listItemMenu__2O2vA label:hover svg {
    fill: rgb(var(--main-color-1));
}

.styles_listItemMenu__2O2vA input:checked + label::before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    cursor: default;
}

.styles_listItemMenu__2O2vA ul {    
    position: absolute;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    right: 75%;
    top: 0;

    -webkit-transform: translateY(-1.65rem);

            transform: translateY(-1.65rem);

    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    gap: .5rem;

    margin: 0;
    padding: .5rem;
    border-radius: 1rem;

    opacity: 0;
    pointer-events: none;

    -webkit-transition: 200ms ease;

    transition: 200ms ease;
}

.styles_listItemMenu__2O2vA input:checked ~ ul {
    top: 50%;
    right: 100%;

    opacity: 1;
    pointer-events: all;
}

.styles_listItemMenu__2O2vA ul li {
    display: grid;
    justify-content: stretch;
}

.styles_listItemMenu__2O2vA ul li a {
    font-weight: 500;
    margin: 0;
    padding: .5em;
    border-radius: .75em;

    text-decoration: none;
    color: rgb(var(--text-color));

    -webkit-transition: 300ms ease-in-out;

    transition: 300ms ease-in-out;
}

.styles_listItemMenu__2O2vA ul li a:hover {
    background-color: rgb(var(--main-color-1));
    color: white;
}

.styles_QRCode__3h1gO {
    display: grid;
    grid-gap: .75rem;
    gap: .75rem;
}

.styles_QRCode__3h1gO > table {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_QRCode__3h1gO > table tbody,
.styles_QRCode__3h1gO > table tr {
    display: contents;
}

.styles_QRCode__3h1gO > table tr th {
    text-align: start;
    display: inline-flex;
    justify-content: space-between;
    grid-gap: .5rem;
    gap: .5rem;
}

.styles_QRCode__3h1gO > table tr th i {
    all: unset;
}

.styles_QRCode__3h1gO > figure {
    padding: 10px;
    padding-bottom: 8px;

    --thickness: .15rem;
    --color: rgb(var(--text-color));
    --width: 2rem;
    --gradient: #0000 90deg, var(--color) 0;
  
    border: var(--thickness) solid transparent;
    background:
        conic-gradient(from 90deg  at top    var(--thickness) left  var(--thickness),var(--gradient)) 0    0,
        conic-gradient(from 180deg at top    var(--thickness) right var(--thickness),var(--gradient)) 100% 0,
        conic-gradient(from 0deg   at bottom var(--thickness) left  var(--thickness),var(--gradient)) 0    100%,
        conic-gradient(from -90deg at bottom var(--thickness) right var(--thickness),var(--gradient)) 100% 100%;
    background-size: var(--width) var(--width);
    background-origin: border-box;
    background-repeat: no-repeat;
}

.styles_QRCode__3h1gO > figure img {
    width: 300px;
    aspect-ratio: 1 / 1;
    border-radius: .25rem;
    background: rgba(var(--bg-color-1), .5);
}

.styles_nodata__3RD2n {
    margin: 0;
    padding: 0 2rem;

    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .styles_component__k9WgY.styles_pabx__2Rk_P header {
        padding: 2rem;
        border-radius: 1rem;
    
        display: grid;
        grid-template-columns: 1fr -webkit-max-content;
        grid-template-columns: 1fr max-content;
        grid-template-rows: auto;
        grid-gap: 3rem 0;
        gap: 3rem 0;
    }

    .styles_component__k9WgY .styles_form__3XKoO {
        padding: 1rem 0;
    }

    .styles_component__k9WgY .styles_form__3XKoO .styles_fieldsetButton__1AWMS legend {
        width: min(calc(100vw - 10rem), 350px);
    }

    .styles_info__1-E-U {
        overflow: hidden;
    }

    .styles_info__1-E-U h2 {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .styles_info__1-E-U h2 i {
        display: none;
    }

    .styles_pabx__2Rk_P .styles_info__1-E-U {
        grid-column: span 3;
    }

    .styles_component__k9WgY.styles_pabx__2Rk_P header th,
    .styles_component__k9WgY.styles_pabx__2Rk_P header td {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 425px) {
    .styles_component__k9WgY header {
        grid-gap: 0;
        gap: 0;
    }
    .styles_pabx__2Rk_P .styles_info__1-E-U {
        display: none;
    }
}


/* telephones */

.styles_telephones__Rs7Wz .styles_list__1i5vc .styles_check__3OWNd {
    display: flex;
}

.styles_telephones__Rs7Wz .styles_list__1i5vc .styles_check__3OWNd input {
    margin: auto;
    -webkit-transform: none;
            transform: none;
    font-size: 1.5em;
}

.styles_telephones__Rs7Wz .styles_list__1i5vc .styles_check__3OWNd input:checked {
    cursor: default;
    pointer-events: none;
}


/* audios */

.styles_audios__2yAKX .styles_wrapper__2YQqh {
    padding-top: 1rem;
}

.styles_audios__2yAKX .styles_wrapper__2YQqh > section {
    padding-bottom: 1rem;
}

.styles_audios__2yAKX .styles_wrapper__2YQqh > *:first-child {
    padding-left: 1rem;
}

.styles_audios__2yAKX .styles_list__1i5vc table thead tr th {
    padding-top: .25rem;
}

@media screen and (max-width: 768px) {
    .styles_component__k9WgY.styles_audios__2yAKX .styles_list__1i5vc table colgroup col:first-of-type {
        width: 99%;
    }

    .styles_component__k9WgY.styles_audios__2yAKX .styles_list__1i5vc table colgroup col:nth-last-of-type(2) {
        width: 0;
        display: none;
    }

    .styles_component__k9WgY.styles_audios__2yAKX .styles_list__1i5vc table thead th:nth-last-of-type(2),
    .styles_component__k9WgY.styles_audios__2yAKX .styles_list__1i5vc table thead tr th:nth-last-of-type(2),
    .styles_component__k9WgY.styles_audios__2yAKX .styles_list__1i5vc table tbody tr td:nth-last-of-type(2) {
        display: none;
    }
}


/* uras */

.styles_uras__1zY0r .styles_form__3XKoO .styles_options__26n-c > div {
    grid-column: 1;
}


/* queues */

.styles_queues__3hmHs .styles_form__3XKoO fieldset:first-of-type > div {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(2, 1fr);
}

.styles_queues__3hmHs .styles_form__3XKoO fieldset:last-of-type > div {
    grid-column: 1;
}


/* report */

.styles_report__2-aUo .styles_selected__aDn2U {
    display: grid;
    grid-gap: .75rem;
    gap: .75rem;
}

.styles_report__2-aUo .styles_selected__aDn2U > h3 {
    margin: 0;
}

.styles_report__2-aUo .styles_selected__aDn2U > audio::-webkit-media-controls-panel {
    padding: 0;
}

.styles_report__2-aUo .styles_selected__aDn2U > table {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_report__2-aUo .styles_selected__aDn2U > table tbody,
.styles_report__2-aUo .styles_selected__aDn2U > table tr {
    display: contents;
}

.styles_report__2-aUo .styles_selected__aDn2U > table tr th {
    text-align: start;
    display: inline-flex;
    justify-content: space-between;
    grid-gap: .5rem;
    gap: .5rem;
}

.styles_report__2-aUo .styles_selected__aDn2U > table tr th i {
    all: unset;
}
.styles_audio__3eZjB {
    height: 3rem;
    background: transparent;
}

.styles_audio__3eZjB::-webkit-media-controls-enclosure {
    background: transparent;
}

.styles_audio__3eZjB::-webkit-media-controls-panel {
    -webkit-filter: opacity(.75);
            filter: opacity(.75);
}

table .styles_audio__3eZjB {
    margin-top: -1rem;
    margin-bottom: -1rem;
}

form .styles_audio__3eZjB {
    margin-bottom: 1rem;
    align-self: center;
}

/* dark theme */

html[class="dark-theme"] .styles_audio__3eZjB::-webkit-media-controls-panel {
    -webkit-filter: invert() opacity(.75);
            filter: invert() opacity(.75);
}
.styles_rowler__139dt {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.styles_rowler__139dt input {
    max-width: 5rem;
    padding: 1rem;
    font-size: 1rem;
}

.styles_rowler__139dt label {
    margin-bottom: .25rem;
}
.styles_main__2JfDd {
    display: grid;
    grid-gap: 3.5rem;
    gap: 3.5rem;
    padding-bottom: 1.5rem !important;
    grid-auto-rows: -webkit-max-content;
    grid-auto-rows: max-content;
}

.styles_main__2JfDd > * {
    min-height: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.styles_nodata__39zOe {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}


/* credits */

.styles_credits__2ouPR {
    display: grid;
    grid-template-columns: 1.25fr .75fr;
    border-radius: 1rem;
}

.styles_credits__2ouPR > div {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
}

.styles_credits__2ouPR > div:last-of-type {
    grid-template-columns: 1fr;
}

.styles_credits__2ouPR > div > section {
    flex-grow: .5;
}

.styles_credits__2ouPR > div > section:only-child {
    width: 100%;
    flex-grow: 1;
}

.styles_credits__2ouPR > div > section:only-child h3 {
    font-size: 2rem;
}

.styles_credits__2ouPR > div > h2 {
    width: 100%;
    margin: 0;
    grid-column: span 2;
}

.styles_credits__2ouPR table {
    display: grid;
    grid-template-columns: -webkit-max-content minmax(10rem, 1fr);
    grid-template-columns: max-content minmax(10rem, 1fr);
    width: auto;
    grid-gap: .5rem;
    gap: .5rem;
    padding-bottom: .5rem;
}

.styles_credits__2ouPR table tbody ,
.styles_credits__2ouPR table tr {
    display: contents;
}

.styles_credits__2ouPR table th {
    text-align: start;
}

.styles_credits__2ouPR table td {
    text-align: end;
}

.styles_credits__2ouPR div[class*="formControl"] {
    min-width: unset;
}

.styles_credits__2ouPR form {
    display: flex;
    flex-direction: column;
}

.styles_credits__2ouPR form button {
    margin-left: auto;
}

@media screen and (max-width: 768px) {    
    .styles_credits__2ouPR {
        grid-template-columns: 1fr;
    }

    .styles_credits__2ouPR > div > h2 {
        grid-column: span 1;
    }

    .styles_credits__2ouPR > div {
        grid-template-columns: 1fr;
    }

    .styles_credits__2ouPR div[class*="formControl"] {
        min-width: 100%;
    }
}


/* extract */

.styles_extract__ypxs4 h2 {
    padding-left: 2rem;
}

.styles_extract__ypxs4 table {
    width: 100%;
}

.styles_extract__ypxs4 table th:first-of-type {
    text-align: start;
}

.styles_extract__ypxs4 table thead th,
.styles_extract__ypxs4 table td {
    text-align: end;
}

.styles_extract__ypxs4 table thead th:nth-child(2),
.styles_extract__ypxs4 table td:nth-child(2):not(:last-child) {
    text-align: center;
}

.styles_extract__ypxs4 table td,
.styles_extract__ypxs4 table th {
    font-size: 1.25rem;
}

.styles_extract__ypxs4 table tfoot td {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .styles_extract__ypxs4 table thead th:nth-child(3),
    .styles_extract__ypxs4 table td:nth-child(3):not(:last-child) {
        display: none;
    }
}
