:root {
    --hover-color: rgb(var(--main-color-2));
}

.navbar * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 20rem;

    display: grid;
    grid-template-rows: auto 1fr auto;

    z-index: 999;

    transition: width 300ms ease-in-out;
}

.navbar > * {
    min-width: 0;
}

.navbar ~ main,
.navbar ~ header {
    width: calc(100% - 20rem);
    float: right;
    padding: 0 2rem;
    padding-right: 12rem;
    transition: width 300ms ease-in-out, padding 300ms ease-in-out;
}

.navbar ~ header {
    height: 7.5rem;
    display: flex;
}

.navbar.collapsed {
    width: 6rem;
}

.navbar.collapsed ~ main,
.navbar.collapsed ~ header {
    width: calc(100% - 6rem);
    padding: 0 6rem;
    padding-right: 12rem;
}

.navbar ~ header h1 {
    margin: auto 0;
}

.navbarHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7.5rem;
    padding: 0 1rem;

    transition: 200ms ease-in-out;
}

.navbar:not(.collapsed) .navbarHeader {
    padding: 0 2.5rem 0 2.75rem;

}

.navbar .logo {
    width: 100%;
    display: flex;
    align-items: center;
    transition: all 300ms 100ms ease-in-out;
}

.navbar .logo > img {
    height: 2rem;
}

.navbar.collapsed .logo {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s;
    width: 0;
}

.navbarHeader .menuButton {
    all: unset;
    background: none;
    border: none;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 2.5rem;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.navbarHeader .menuButton .menuButtonBar {
    width: 2rem;
    height: .3rem;
    background: rgb(var(--text-color));
    border-radius: 1rem;
    transition: all 50ms ease-in-out;
    display: flex;
}

.navbarHeader .menuButton .menuButtonBar::before,
.navbarHeader .menuButton .menuButtonBar::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: .3rem;
    background: rgb(var(--text-color));
    border-radius: 1rem;
    transition: all 200ms ease-in-out;
}

.navbarHeader .menuButton .menuButtonBar::before {
    height: .25rem;
    transform: translateY(-.6rem);
}

.navbarHeader .menuButton .menuButtonBar::after {
    height: .25rem;
    transform: translateY(.6rem);
}

.navbar:not(.collapsed) .navbarHeader .menuButton .menuButtonBar {
    background-color: transparent;
    pointer-events: none;
}

.navbar:not(.collapsed) .navbarHeader .menuButton .menuButtonBar::before {
    transform: rotate(45deg);
}

.navbar:not(.collapsed) .navbarHeader .menuButton .menuButtonBar::after {
    transform: rotate(-45deg);
}

.navbar .navbarList {
    height: 100%;
    padding: 0 1rem;

    list-style: none;
    display: flex;
    flex-direction: column;
    
    gap: .5rem;
}

.navbar .navbarList li {
    position: relative;
    border-radius: 1rem;

    transition: all 200ms ease-in-out;
}

.navbar .navbarList > li:hover,
.navbar .navbarList li.active {
    background: var(--hover-color);
}

.navbar .navbarList li i {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 2rem;
    padding: 1rem;
    color: rgb(var(--text-color));
}

.navbar .navbarList li i * {
    color: rgb(var(--text-color));
}


.navbar .navbarList li i,
.navbar .navbarList li i * {
    transition: 300ms ease-in-out;
}

.navbar:not(.collapsed) .navbarList li i {
    padding: 1rem 1.75rem;
}

.navbar .navbarList li .expandLink {
    display: flex;
    align-items: center;
}

.navbar.navbar .expandLink {
    cursor: default;
}

.navbar .navbarList li .navbarNestedList li > a,
.navbar .navbarList li > a {
    cursor: pointer;
}

.navbar .navbarList li .expandableItem .expandLink .expandIcon {
    font-size: 1.5rem;
    margin-left: auto;
    transition: all 200ms ease-in-out;
}

.navbar .navbarList li .expandableItem.expanded .expandLink .expandIcon {
    transform: rotate(-180deg);
}

.navbar .navbarList li a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.navbar .navbarList li .itemName {
    font-weight: 400;
    color: rgb(var(--text-color));
    transition: all 300ms ease-in-out;
}

.navbar .navbarList > li:hover,
.navbar .navbarList li.active {
    background: var(--hover-color);
}

.navbar .navbarList > li:hover *,
.navbar .navbarList li.active * {
    color: white;
}

.navbar .navbarList li .navbarNestedList {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;

    width: 100%;
    top: 50%;
    left: 0;

    border-radius: 1rem;

    z-index: 2;

    opacity: 0;
    pointer-events: none;
    transition: 200ms ease-in-out;

    gap: .5em;
}

.navbar .navbarList li .tooltip {
    display: none;
}

.navbar .navbarList li .navbarNestedList,
.navbar .navbarList li .tooltip {
    padding: .5em;

    font-weight: 600;

    min-width: max-content;
}

.navbar .navbarList li .tooltip span {
    padding: .5em;
    color: rgb(var(--text-color));
    font-weight: 600;
}


.navbar .navbarList li .expandableItem.expanded .navbarNestedList {
    opacity: 1;
    pointer-events: all;
    top: 90%;
    left: 1rem;
}

.navbar .navbarList li .expandableItem.expanded .navbarNestedList::before {
    content: '';
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200vw;
    height: 200vh;
    transform: translateY(-50%) translateX(-50%)
}

.navbar .navbarList li .navbarNestedList a {
    color: rgb(var(--text-color));
    font-weight: 600;
    white-space: nowrap;
    padding: 0.5em;
	border-radius: 0.75em;
    transition: all 300ms ease-in-out;
}

.navbar .navbarList li .navbarNestedList a span {
    font-weight: inherit;
    color: rgb(var(--text-color));;
}

.navbar .navbarList li .navbarNestedList a:hover {
    background-color: rgb(var(--main-color-2));
}

.navbar .navbarList li .navbarNestedList a:hover * {
    color: white;
}

.navbar.collapsed .navbarList li .navbarNestedList,
.navbar.collapsed .navbarList li .tooltip {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 100%;
    top: 0;
    border-radius: 1rem;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
}

.navbar.collapsed .navbarList li .navbarNestedList::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1rem;
    width: 100%;
    height: 100%;
}

.navbar.collapsed .navbarList li:hover .navbarNestedList,
.navbar.collapsed .navbarList li .expandableItem.expanded .navbarNestedList,
.navbar.collapsed .navbarList li:hover .tooltip {
    top: 10%;
    left: calc(100% + 1rem);
    pointer-events: auto;
    opacity: 1;
}

.navbar .navbarList li .navbarNestedList .itemName {
    display: none;
}

.navbar.collapsed .navbarList li .navbarNestedList .itemName,
.navbar .navbarList li .expandableItem.expanded .navbarNestedList .itemName {
    opacity: 1;
    display: block;
}

.navbar.collapsed .navbarList li a .itemName,
.navbar.collapsed .navbarList li a .expandIcon {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0ms;
}

.navbar .profile {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile .profilePic {
    position: relative;
    display: block;

    width: 4rem;
    height: 4rem;

    overflow: hidden;
    color: transparent;

    margin: 1rem .75rem 1rem 1.75rem;

    border-radius: 1rem;

    background-color: rgb(var(--main-color-0));
    
    transition: margin 200ms ease-in-out;
}

.profile .profilePic.notFound {
    outline: solid 2rem rgb(var(--main-color-0));
    outline-offset: -2rem;
}

.profile .profilePic.notFound::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 75%;
    top: 60%;
    left: 50%;
    transform: translateX(-50%) scale(1.25);
    border: solid .25rem rgb(var(--main-color-0));
    border-radius: 45%;
    background-color: white;
}

.profile .profilePic.notFound::after {
    content: '';
    position: absolute;
    width: 55%;
    height: 55%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-70%);
    border: solid .25rem rgb(var(--main-color-0));
    border-radius: 100%;
    background-color: white;
}

.profile .profileName {
    font-size: 1rem;
    margin-right: auto;

    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
    white-space: nowrap;
}

.navbar .profile .profileMenu {
    position: relative;
}

.navbar .profile .profileMenuButton {
    font-size: 1.5rem;

    padding: .5rem;
    margin-right: 2.25rem;

    display: flex;

    cursor: pointer;
}

.navbar .profile .profileMenuToggle {
    display: none;
}

.navbar .profile .profileMenu .profileMenuOptions {
    position: absolute;
    bottom: 50%;
    left: 0;

    display: flex;
    flex-direction: column;

    padding: 0.5rem;
    border-radius: 1rem;

    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;

    gap: .5rem;
}

.navbar .profile .profileMenu .profileMenuToggle {
    position: relative;
}

.navbar .profile .profileMenu .profileMenuToggle:checked ~ .profileMenuOptions {
    left: 50%;
    bottom: 0;
    opacity: 1;
    pointer-events: all;
}

.navbar .profile .profileMenu .profileMenuToggle:checked ~ .profileMenuButton::before {
    content: '';
    position: fixed;
    width: 200vw;
    height: 200vh;
    transform: translateY(-50%) translateX(-50%);
    cursor: default;
}

.navbar .profile .profileMenu .profileMenuOptions li label {
    display: block;

    padding: 0.5em;
	border-radius: 0.75em;

    text-decoration: none;
    font-weight: 600;
    color: rgb(var(--text-color));

    white-space: nowrap;
    transition: all 300ms ease-in-out;

    cursor: pointer;
}

.navbar .profile .profileMenu .profileMenuOptions li label:hover {
    color: white;
    background: rgb(var(--main-color-2));
}

.navbar.collapsed .profileMenu {
    visibility: hidden;
}

.navbar.collapsed .profile .profilePic {
    margin: 1rem;
}

iframe[id="omnizeButtonFrame"],
iframe[id="omnizeWgtFrame"] {
	pointer-events: all;
}

@media screen and (max-width: 1024px) {
    .navbar ~ main,
    .navbar ~ header {
        padding: 0 1rem;
        padding-right: 5rem;
    }
    
    .navbar.collapsed ~ main,
    .navbar.collapsed ~ header {
        padding: 0 2rem;
        padding-right: 5rem;
    }
}


@media screen and (max-width: 768px) {
    .navbar:not(.collapsed) {
        position: fixed;
        background: linear-gradient(to bottom, rgb(var(--bg-color-0)), rgb(var(--bg-color-1)));
        outline: solid rgba(var(--bg-color-1), 1) thin;
        box-shadow: 
            0 25px 25px -15px rgba(0, 10, 20, 0.1);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .navbar:not(.collapsed) {
            background: linear-gradient(to bottom, rgba(var(--bg-color-0), 0.25), rgba(var(--bg-color-1), 0.5));
            backdrop-filter: blur(.5em);
        }
    }

    .navbar ~ main,
    .navbar ~ header {
        width: calc(100% - 6rem);
        padding: 0 1rem;
    }
    
    .navbar.collapsed ~ main,
    .navbar.collapsed ~ header {
        width: calc(100% - 6rem);
        padding: 0 1rem;
    }
}