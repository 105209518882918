.main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.search {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.search label {
    font-size: 1.5rem;
}

.search input {
    border-radius: 5rem;
    flex-grow: .25;
    max-width: 24rem;
}

.list {
    all: unset;
    appearance: none;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(calc(100vw - 20rem) / 3), auto));
    gap: 1.5rem;
}

.pabx {
    padding: 2rem;
    padding-top: 1.5rem;
    border-radius: 1rem;

    cursor: pointer;
}

.pabx h3 {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-weight: 500;
}

.pabx h3::after {
    content: ': : :';
    color: rgba(var(--text-color), .1);
    font-size: 2rem;
}

.pabx table {
    width: 100%;

    display: grid;
    gap: 1rem;

    font-size: 1.25rem;
}

.pabx tbody {
    width: 100%;

    display: grid;
    grid-template-columns: auto minmax(min-content, auto);
    gap: .25rem .5rem;
}

.pabx tbody.mainData {
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
}

.pabx tr {
    display: contents;
}

.pabx th {
    text-align: start;
    font-weight: normal;
}

.pabx td {
    text-align: end;
    font-weight: 500;
}

.nodata {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 100;
}

@media screen and (max-width: 1024px) {
    .search input {
        max-width: 100%;
        flex-grow: .5;
    }

    .list {
        grid-template-columns: repeat(auto-fit, minmax(calc(calc(100vw - 20rem) / 2), auto));
    }

    .pabx table {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .list {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 425px) {
    .search label {
        display: none;
    }

    .search input {
        width: calc(100vw - 8rem);
        margin: auto;
    }

    .pabx table td {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 1600px) {
    .list {
        grid-template-columns: repeat(auto-fill, minmax(28rem, auto));
    }
}