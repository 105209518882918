.main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1.5rem !important;
}

.nodata {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 100;
}