.audio {
    height: 3rem;
    background: transparent;
}

.audio::-webkit-media-controls-enclosure {
    background: transparent;
}

.audio::-webkit-media-controls-panel {
    filter: opacity(.75);
}

table .audio {
    margin-top: -1rem;
    margin-bottom: -1rem;
}

form .audio {
    margin-bottom: 1rem;
    align-self: center;
}

/* dark theme */

html[class="dark-theme"] .audio::-webkit-media-controls-panel {
    filter: invert() opacity(.75);
}