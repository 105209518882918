.main {
    display: grid;
    gap: 3.5rem;
    padding-bottom: 1.5rem !important;
    grid-auto-rows: max-content;
}

.main > * {
    min-height: 0;
    height: fit-content;
}

.nodata {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 100;
    text-align: center;
}


/* credits */

.credits {
    display: grid;
    grid-template-columns: 1.25fr .75fr;
    border-radius: 1rem;
}

.credits > div {
    display: grid;
    gap: 2rem;
    padding: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
}

.credits > div:last-of-type {
    grid-template-columns: 1fr;
}

.credits > div > section {
    flex-grow: .5;
}

.credits > div > section:only-child {
    width: 100%;
    flex-grow: 1;
}

.credits > div > section:only-child h3 {
    font-size: 2rem;
}

.credits > div > h2 {
    width: 100%;
    margin: 0;
    grid-column: span 2;
}

.credits table {
    display: grid;
    grid-template-columns: max-content minmax(10rem, 1fr);
    width: auto;
    gap: .5rem;
    padding-bottom: .5rem;
}

.credits table tbody ,
.credits table tr {
    display: contents;
}

.credits table th {
    text-align: start;
}

.credits table td {
    text-align: end;
}

.credits div[class*="formControl"] {
    min-width: unset;
}

.credits form {
    display: flex;
    flex-direction: column;
}

.credits form button {
    margin-left: auto;
}

@media screen and (max-width: 768px) {    
    .credits {
        grid-template-columns: 1fr;
    }

    .credits > div > h2 {
        grid-column: span 1;
    }

    .credits > div {
        grid-template-columns: 1fr;
    }

    .credits div[class*="formControl"] {
        min-width: 100%;
    }
}


/* extract */

.extract h2 {
    padding-left: 2rem;
}

.extract table {
    width: 100%;
}

.extract table th:first-of-type {
    text-align: start;
}

.extract table thead th,
.extract table td {
    text-align: end;
}

.extract table thead th:nth-child(2),
.extract table td:nth-child(2):not(:last-child) {
    text-align: center;
}

.extract table td,
.extract table th {
    font-size: 1.25rem;
}

.extract table tfoot td {
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .extract table thead th:nth-child(3),
    .extract table td:nth-child(3):not(:last-child) {
        display: none;
    }
}