.list li {
    padding: .5rem 0;
    position: relative;
}

.item {
    position: relative;

    display: flex;
    align-items: center;
    gap: 1rem;
    
    padding: 0 1rem;
    border-radius: 1rem;
}

.item > i {
    display: contents;
}

.item > i > svg {
    margin-left: auto;
    font-size: 2rem;
    fill: rgba(var(--text-color), .3);
    margin-block: .65rem;
    transform: rotateZ(90deg);
}

.content {
    display: contents;
    position: relative;
}

.content * {
    margin: 0;
    grid-row: 1;
}

/* ordered */
ol .item::after {
    content: attr(id);
    color: rgba(var(--text-color), .5);
    font-weight: 500;
    z-index: 1;
    order: -1;
}