.formControl {
	min-width: 350px;

	display: grid;
	grid-template-rows: minmax(1rem, auto) minmax(3rem, auto) minmax(1rem, auto);
	grid-template-columns: auto;
	row-gap: 0.5em;

	margin-bottom: 1em;

}

.formControl label {
	padding-left: 1em;
	text-transform: uppercase;
	font-weight: bold;
}

.formControl .input:not([type="checkbox"]):not([type="radio"]) {
	height: 3.125rem;
}

.formControl .select {
	position: relative;
	display: grid;
	max-width: 100%;

	border-radius: 1em;
}

.formControl .select select {
	display: none;
}

.formControl .select .input {
	--border-color: var(--text-color);
	--border-visibility: 0;

	display: block;
	position: relative;
	margin: 0;
	padding: 1em;
	padding-right: 2.5em;

	border-radius: inherit;

	text-transform: none;
	font-weight: normal;
	color: rgb(var(--text-color));
	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

    background: rgba(var(--input-color), 1);
	box-shadow:
		inset 0 10px 15px -10px rgba(var(--text-color), 0.25),
		inset 0 0 0 2em rgba(var(--input-color), 0.75),
		0 0 0 0.125em rgba(var(--border-color), var(--border-visibility));

	outline-style: solid;
	outline-width: 0.35em;
	outline-offset: -0.1em;
	outline-color: transparent;

	transition: 150ms ease-in-out;
	cursor: pointer;
}

.formControl .toggle {
	display: none;
}

.formControl .select .value[value=""] ~ .input {
	color: rgb(var(--placeholder-color));
}

.formControl .select .toggle:checked ~ .input {
	outline-color: rgba(var(--border-color), 0.25);
	--border-color: var(--main-color-1);
	--border-visibility: 1;
}

.formControl .select .input::before {
	content: '';
	display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: transparent;

	isolation: isolate;
	z-index: 1;

	cursor: default;
}

.formControl .select .toggle:checked ~ .input::before {
	display: block;
}

.formControl .select .input::after {
	font-size: .75rem;
	content: '';
	position: absolute;
	width: 1em;
	height: 1em;
	right: 1.5em;
	top: calc(50% - .2em);
	
	background-color: rgb(var(--placeholder-color));;
	clip-path: polygon(10% 0, 90% 0, 100% 10%, 100% 20%, 55% 60%, 45% 60%, 0 20%, 0 10%);

	transform-origin: 50% 25%;
	transition: 200ms ease-in-out;
}

.formControl .select .toggle:checked ~ ::after {
	transform: rotateZ(180deg);
}

.formControl .select .options {
	position: absolute;
	top: 0;
	margin: 0;
	padding: 0;
	padding-top: 1em;
	border-radius: 1em;


	width: 100%;
	opacity: 0;
	height: fit-content;
	max-height: 0;
	overflow-x: hidden;
	overflow-y: hidden;
	
	z-index: 1;
	pointer-events: none;
	
	transition: 200ms ease-in-out, max-height 200ms 200ms ease-in-out;
}

.formControl .select .optionsList {
	padding: 0 0.5em;
	padding-bottom: 1em;
	position: relative;
	list-style: auto;
	margin: 0;
	user-select: none;
	pointer-events: none;

	width: 100%;
	height: max-content;
	max-height: 8.5em;
	overflow-x: hidden;
	overflow-y: auto;
}

.formControl .select .optionsList li {
	display: block;
	padding: 0.25em 0.5em;
	border-radius: 0.5em;

	transition: 100ms 25ms ease-in-out, color 0ms 25ms;

	font-weight: 500;
	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	cursor: pointer;
}

.formControl .select .optionsList li:hover {
	background-color: rgba(var(--main-color-1), 0.75);

	transition: 100ms ease-in-out, color 25ms 25ms;

	color: white;
}

.formControl .select .optionsList li.disabledOption {
	font-weight: normal;
	cursor: default;

	color: rgb(var(--placeholder-color));
}

.formControl .select .optionsList li.disabledOption:hover {
	font-weight: normal;
	background-color: rgb(var(--placeholder-color));
	color: white;
}

.formControl .select .toggle:checked ~ .options {
	top: calc(100% - 0.5em);
	
	opacity: 1;
	max-height: 12.5em;
	
	transition: 200ms ease-in-out, max-height 0ms;
}

.formControl .select .toggle:checked ~ .options .optionsList {
	pointer-events: all;
}

.formControl .select .options .searchbox {
	padding-right: 1em;
}

.formControl .select .options .searchbox input {
	width: 100%;
	padding: 0.5em;
	margin: 0.5em;
	margin-right: 0;
	border-radius: 0.5em;

	background-color: rgba(var(--input-color));

	outline: none;
	box-shadow: 
		inset 0 10px 15px -10px rgba(var(--text-color), 0.25),
		inset 0 0 0 .1em rgba(var(--border-color), 0.1);

	pointer-events: all;
}

.formControl .select .options .searchbox input:focus {
	box-shadow: 
		inset 0 10px 15px -10px rgba(var(--text-color), 0.25),
		inset 0 0 0 .25em rgba(var(--border-color), 0.25),
		inset 0 0 0 .1em rgba(var(--border-color), 0.25);
}

.formControl .select .options .optionsList::-webkit-scrollbar-track {
	margin: 0 !important;
	margin-bottom: 1em !important;
}

.formControl .radio {
	display: flex;
	flex-flow: wrap;
	padding: 0;
	padding-left: 1em;
	padding-top: 0.25em;
	gap: .5em 1em;
	margin: 0;
}

.formControl .checkboxes li .label,
.formControl .radio li .label {
	position: relative;
	margin-right: 0.5em;
	padding-left: 0.5em;

	text-transform: none;
	font-weight: 500;

	transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.formControl .checkboxes .input:not(:disabled):hover ~ .label,
.formControl .radio .input:not(:disabled):not(:checked):hover ~ .label {
	opacity: 1;
	width: 100%;

	color: rgb(var(--main-color-1));
}

.formControl .radio li input:disabled ~ .label {
	opacity: 0.5 !important;
}

.formControl .radio li input:disabled ~ .label::after {
	all: unset;
}

.formControl .checkboxes {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, auto));
	gap: .5rem;
}

.formControl .checkboxes li {
	display: block;
}

.formControl .password {
	position: relative;

	isolation: isolate;
}

.formControl .password .input {
	padding-right: 3.5em;

	width: 100%;
}

.formControl .password .toggleIcon {
	font-size: .85em;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 3.5em;
	height: calc(100% - .5em);
	background-color: transparent;

	margin: .25em;

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 1;
}

.formControl .password .toggleIcon::before {
	content: '';
	position: absolute;

	width: 1.5em;
	height: 1.5em;

	border-radius: 100% 0;

	background-color: transparent;
	box-shadow: inset 0.125em 0.125em 0 .125em rgb(var(--placeholder-color));

	transition: 50ms ease-out;

	transform: rotateZ(45deg);
}

.formControl .password .toggleIcon::after {
	content: '';
	position: absolute;

	width: .55em;
	height: .55em;

	background-color: rgb(var(--placeholder-color));

	border-radius: 100%;

	transition: 50ms ease-in, border-radius 0ms 50ms;
}

.formControl .password .toggle:checked ~ .toggleIcon::before {
	height: 0.25em;
	border-radius: 0;

	width: 2em;

	background: rgb(var(--placeholder-color));

	transform: rotateZ(70deg) rotateX(90deg) rotateY(-90deg);

	transition: 50ms ease-in;
}

.formControl .password .toggle:checked ~ .toggleIcon::after {
	width: 2em;
	height: 0.225em;

	border-radius: 0;
	border: none;

	transition: 50ms ease-in, border-radius 0ms 50ms;
}

.formControl .file {
	position: relative;
	border-radius: 1rem;
	background-clip: border-box;
}

.formControl .file .input {
	display: none;
}

.formControl .file .filename {
	width: 100%;
}

.formControl .file .button {
	--shadow-color: var(--input-shadow-color);
	--light-color: var(--bg-color-1);

	position: absolute;
	top: 0;
	right: 0;

	height: 100%;
	aspect-ratio: 1.5 / 1;

	display: flex;
	justify-content: center;
	align-items: center;

	background: linear-gradient(to right, transparent, rgb(var(--input-color)));
	box-shadow:
		inset -.5rem -.25rem .25rem -.5rem rgba(var(--shadow-color), .3),
		inset -.5rem 0 .25rem -.5rem rgba(var(--shadow-color), .3),
		inset -.5rem .25rem .25rem -.5rem rgba(var(--shadow-color), .3),
		inset -.5rem 0 .35rem -.5rem rgb(var(--input-color)),
		inset -.5rem 0 .5rem -.5rem rgb(var(--light-color));

	border-radius: inherit;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-right: .0125rem solid rgba(var(--input-color), 1);	

	z-index: 1;
	cursor: pointer;

	transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.formControl .file .filename:disabled ~ .button {
	width: 100%;
	padding-right: 1em;
}

.formControl .file .filename:disabled ~ .button > *{
	margin-left: auto;
}

.formControl .file .button:hover {
	--light-color: var(--main-color-1);
	--shadow-color: var(--main-color-1);
}

.formControl .file .button svg {
	transition: 1000ms cubic-bezier(0.5, 3, 0, -0.5);
}

.formControl .file .button:hover svg {
	fill: rgb(var(--main-color-1));
}

.formControl.invalid .file .button:hover svg {
	fill: rgb(var(--main-color-4));
}

.formControl.invalid .file .button:hover {
	--light-color: var(--main-color-4);
	--shadow-color: var(--main-color-4);
}

.formControl .file .button i {
	font-size: 2rem;
	padding-top: .25rem;
}

.formControl .file .filename:focus ~ .button {
	box-shadow: none;
}

.formControl .group {
	display: flex;
	flex-direction: row;
	gap: .5rem;
	width: 100%;
}

.formControl .group > * {
	min-width: 0;
	width: 100%;
}

.formControl .group > * {
	border-radius: .25rem;
}

.formControl .group > *:first-child {
	border-bottom-left-radius: 1rem;
	border-top-left-radius: 1rem;
}

.formControl .group > *:last-child {
	border-bottom-right-radius: 1rem;
	border-top-right-radius: 1rem;
}

.formControl .group button {
	margin: 0;
}

.formControl strong {
	padding-left: 1em;

	font-size: 0.75em;
	font-weight: normal;

	color: rgba(var(--main-color-4), 1);

	opacity: 0;
	pointer-events: none;
	user-select: none;
}

.formControl strong::after {
	content: '.';
    visibility: hidden;
}

.formControl.invalid .input,
.formControl.invalid .select .input,
.formControl.invalid .password .input,
.formControl.invalid .file .filename {
	--border-color: var(--main-color-4);
	--border-visibility: 0.75;
}

.formControl.invalid label {
	color: rgba(var(--main-color-4), 1);
}

.formControl.invalid strong {
	opacity: 1;
	pointer-events: all;
	user-select: all;
}

@media screen and (max-width: 768px) {
	.formControl {
		min-width: unset;
		width: 100%;
		max-width: min(calc(100vw - 12rem), 350px);
	}
}

@media screen and (max-width: 425px) {
	.formControl {
		width: 100%;
		max-width: max(calc(100vw - 12rem), 250px);
	}
}